import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import classes from './OneNotification.module.css';
import CustomSpinner from '../../../shared/generics/loader/Loader';
import useAxios from '../../../useHooks/useAxios/useAxios';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';
import { useTranslation } from 'react-i18next';

const OneNotification = () => {
  const { request } = useAxios();
  const { messageId } = useParams();
  const [messageData, setMessageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { lang } = useLanguage();
  const { t } = useTranslation();

  const fetchMessage = async () => {
    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request(`/profile/onemessage/${messageId}`);

    setLoading(isLoading);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setMessageData(data?.data);
    }
  };

  const handleRead = async () => {
    const { errorMessage } = await request('/updateMessageStatus', {
      method: 'POST',
      body: { id: messageId },
    });

    if (errorMessage) {
      console.error('Error updating message status:', errorMessage);
    } else {
      setMessageData((prevMessage) => ({ ...prevMessage, status: '0' }));
    }
  };

  useEffect(() => {
    fetchMessage();
  }, [messageId]);

  if (error) {
    return <div className='ec-error'>Muammo: {error}</div>;
  }

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <div className={classes['container']}>
      <button className={classes['back-btn']} onClick={() => navigate(-1)}>
        <svg
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={classes['arrow-icon']}
        >
          <path d='M8 1L2 8L8 15' stroke='#fff' strokeWidth='2' />
        </svg>
        {t('back')}
      </button>
      <h1 className={classes['title']}>{messageData?.created_at}</h1>
      <div className={classes['box']}>
        <p className={`${classes['answer']} gradient-txt`}>
          {lang(messageData, 'message')}
        </p>
        {messageData?.status === '1' && (
          <button className={classes['btn']} onClick={handleRead}>
            O'qildi
          </button>
        )}
      </div>
    </div>
  );
};

export default OneNotification;
