import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';
import './GenericPagination.css';

const GenericPagination = ({ current, total, pageSize, onChange }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {total > 10 && (
        <div className={'paginationContainer'}>
          <Pagination
            current={current}
            total={total}
            pageSize={pageSize}
            onChange={onChange}
            className={'pagination'}
            showLessItems={isMobile}
            showQuickJumper={!isMobile}
            simple={isMobile}
          />
        </div>
      )}
    </>
  );
};

export default GenericPagination;
