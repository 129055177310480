import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { message, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import { Turn } from 'hamburger-react';

import { UserContext } from '../../../context/userContext/userContext';
import AttendenceModal from '../../../pages/home/attendence/Attendence';

import profile from '../../assets/icons/profile-arrow.svg';
import classes from './Navbar.module.css';
import { useTranslation } from 'react-i18next';
import LanguageDropDownComponent from '../../generics/languageDropdown/LanguageDropdown';

const Navbar = () => {
  const navigate = useNavigate();
  const { setToken, user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken('');
    message.success(t('logout_success_message'));
    navigate('/');
    setIsOpen(false);
  };

  const handleItemClick = () => {
    setIsOpen(false);
  };

  const gameItems = [
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/typing'
        >
          {t('typing')}
        </Link>
      ),
      key: 'item-1',
    },
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/multiply'
        >
          {t('multiply')}
        </Link>
      ),
      key: 'item-2',
    },
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/test/categories'
        >
          {t('test')}
        </Link>
      ),
      key: 'item-3',
    },
  ];

  const resourceItems = [
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/walkthrough'
        >
          {t('walkthrough')}
        </Link>
      ),
      key: 'item-1',
    },
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/course'
        >
          {t('video_course')}
        </Link>
      ),
      key: 'item-2',
    },
  ];

  const companyItems = [
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/about'
        >
          {t('about_us')}
        </Link>
      ),
      key: 'item-1',
    },
    {
      label: (
        <Link
          onClick={handleItemClick}
          className={classes['menu-item']}
          to='/news'
        >
          {t('news')}
        </Link>
      ),
      key: 'item-2',
    },
  ];

  const profileItems = [
    {
      label: (
        <div
          className={classes['menu-item']}
          onClick={() => navigate('/settings')}
        >
          <Icon icon='gg:profile' className={classes['icon']} />
          {user?.name.length > 8 ? (
            <span>{user?.name.slice(0, 8)}...</span>
          ) : (
            user?.name
          )}
        </div>
      ),
      key: 'item-1',
    },
    {
      label: (
        <div
          className={classes['menu-item']}
          onClick={() => navigate('/settings/notification')}
        >
          <Icon
            icon='iconamoon:notification-bold'
            className={classes['icon']}
          />
          {t('message')}
        </div>
      ),
      key: 'item-2',
    },
    {
      label: (
        <div className={classes['menu-item']} onClick={handleLogout}>
          <Icon icon='mdi:exit-to-app' className={classes['icon']} />
          {t('logout')}
        </div>
      ),
      key: 'item-3',
    },
  ];

  return (
    <div className={classes['container']}>
      <Link
        className={classes['router-links']}
        to={'/'}
        onClick={handleItemClick}
      >
        <h1 className={`${classes['logo-header']}`}>EduCoin.</h1>
      </Link>

      <div className={`${classes['nav-items']} ${isOpen && classes['open']}`}>
        <Dropdown menu={{ items: gameItems }} trigger={['hover']}>
          <p className={`${classes['nav-item']} ${classes['dropdown-item']}`}>
            {t('game')} <DownOutlined className={classes['arrow-icon']} />
          </p>
        </Dropdown>
        <Dropdown menu={{ items: resourceItems }} trigger={['hover']}>
          <p className={`${classes['nav-item']} ${classes['dropdown-item']}`}>
            {t('source')} <DownOutlined className={classes['arrow-icon']} />
          </p>
        </Dropdown>
        <Dropdown menu={{ items: companyItems }} trigger={['hover']}>
          <p className={`${classes['nav-item']} ${classes['dropdown-item']}`}>
            {t('company')} <DownOutlined className={classes['arrow-icon']} />
          </p>
        </Dropdown>
        <NavLink
          className={classes['router-links']}
          to={'/coin-rating'}
          onClick={handleItemClick}
        >
          <p className={classes['nav-item']}>{t('rating')}</p>
        </NavLink>
        <NavLink
          className={classes['router-links']}
          to='/product'
          onClick={handleItemClick}
        >
          <p className={classes['nav-item']}>{t('purchase')}</p>
        </NavLink>
      </div>

      <div className={classes['flex']}>
        <div className={classes['language-box']}>
          <LanguageDropDownComponent />
        </div>
        <div className={classes['profile']}>
          <Dropdown
            placement='bottomCenter'
            menu={{ items: profileItems }}
            trigger={['hover']}
          >
            {user?.image ? (
              <div
                className={classes['profile-img-wrapper']}
                onClick={handleItemClick}
              >
                <img
                  src={`${BASE_URL}/storage/${user?.image}`}
                  className={classes['profile-img']}
                  alt='profile'
                />
                <Icon
                  icon='iconamoon:arrow-bottom-left-2-bold'
                  className={classes['profile-arrow']}
                />
              </div>
            ) : (
              <img
                className={classes['default-profile-img']}
                src={profile}
                alt=''
                onClick={handleItemClick}
              />
            )}
          </Dropdown>
        </div>
        <Turn toggled={isOpen} toggle={setIsOpen} />
      </div>

      <AttendenceModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default Navbar;
