import React, { createContext, useState } from 'react';
import {
  englishWords,
  russianWords,
  uzbekWords,
} from '../../shared/utils/typingWords';
import { useTranslation } from 'react-i18next';

const TypingResultContext = createContext();

const TypingResultProvider = ({ children }) => {
  const [result, setResult] = useState({
    correctWords: 0,
    mostlyCorrect: 0,
    fullyCorrect: 0,
    letterFlow: 0,
    incorrectLetters: 0,
    incorrectWords: 0,
    letterStroke: 0,
    wpm: 0,
  });
  const [words, setWords] = useState(englishWords);
  const [fixedTime, setFixedTime] = useState(15);
  const [timer, setTimer] = useState(fixedTime);
  const [language, setLanguage] = useState('Ingliz tili');
  const { t } = useTranslation();

  const updateResult = (newResult) => {
    setResult((prevResult) => ({ ...prevResult, ...newResult }));
  };

  const resetTime = () => {
    setTimer(fixedTime);
  };

  const handleLanguageChange = (e) => {
    if (e.key === t('lang_uz')) {
      setWords(uzbekWords);
    } else if (e.key === t('lang_en')) {
      setWords(englishWords);
    } else if (e.key === t('lang_ru')) {
      setWords(russianWords);
    }
  };

  return (
    <TypingResultContext.Provider
      value={{
        result,
        updateResult,
        timer,
        setTimer,
        fixedTime,
        setFixedTime,
        resetTime,
        words,
        setWords,
        handleLanguageChange,
        language,
        setLanguage,
      }}
    >
      {children}
    </TypingResultContext.Provider>
  );
};

export { TypingResultContext, TypingResultProvider };
