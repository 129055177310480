import React from 'react';

import Navbar from '../../shared/layouts/navbar/Navbar';

import avatar1 from '../../shared/assets/images/avatar-1.png';

import classes from './AuthBackground.module.css';

const AuthBackground = () => {
  return (
    <>
      <Navbar />
      <div className={classes['container']}>
        <img className={classes['img']} src={avatar1} alt='avatar-1' />
      </div>
    </>
  );
};

export default AuthBackground;
