import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './NotificationCategory.css';
import { useTranslation } from 'react-i18next';

const NotificationCategory = () => {
  const [activeCategory, setActiveCategory] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const query = new URLSearchParams(location.search);
  const searchChecked = parseInt(query.get('search')) || 0;

  useEffect(() => {
    if (searchChecked == 0) {
      setActiveCategory(t('set_message_seen'));
    } else {
      setActiveCategory(t('set_message_unseen'));
    }
  }, []);

  const handleCategoryChange = (value) => {
    setActiveCategory(value);
    const isRead = value == t('set_message_seen');
    navigate(`?search=${isRead ? '0' : '1'}`);
  };

  return (
    <div className='notification-category-container'>
      <Segmented
        options={[t('set_message_seen'), t('set_message_unseen')]}
        value={activeCategory}
        onChange={handleCategoryChange}
      />
    </div>
  );
};

export default NotificationCategory;
