import React, { createContext, useState, useContext } from 'react';

const MultiplyContext = createContext();

const MultiplyProvider = ({ children }) => {
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [timer, setTimer] = useState(15);
  const [startTime, setStartTime] = useState(null);

  const resetGame = () => {
    setCorrectCount(0);
    setIncorrectCount(0);
    setTimer(15);
    setStartTime(null);
  };

  const calculateSpeed = () => {
    const elapsedTime = (15 - timer) / 60; // Time in minutes
    const speed = elapsedTime > 0 ? correctCount / elapsedTime : 0;
    return speed;
  };

  return (
    <MultiplyContext.Provider
      value={{
        correctCount,
        incorrectCount,
        timer,
        startTime,
        setCorrectCount,
        setIncorrectCount,
        setTimer,
        setStartTime,
        resetGame,
        calculateSpeed,
      }}
    >
      {children}
    </MultiplyContext.Provider>
  );
};

const useMultiply = () => useContext(MultiplyContext);

export { MultiplyProvider, useMultiply };
