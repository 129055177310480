import React from 'react';

import Navbar from '../../shared/layouts/navbar/Navbar';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import Footer from '../../shared/layouts/footer/Footer';

import classes from './Walkthrough.module.css';
import { useTranslation } from 'react-i18next';

const Walkthrough = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className={classes['container']}>
        <h1 className={`${classes['header']} gradient-txt`}>
          {t('source_header')}
        </h1>
        <iframe
          className={classes['img']}
          width='1242'
          src='https://www.youtube.com/embed/EnuvvfMCG-k'
          title='EduCoin - o&#39;quvchilarni rag&#39;batlantiruvchi innovatsion o&#39;quv tizimi | President Tech Award'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>
      </div>
      <Footer />
      <Copywrite />
    </>
  );
};

export default Walkthrough;
