import axios from 'axios';

const useAxios = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const request = async (url, options = {}) => {
    const { method = 'GET', body = null, headers = {} } = options;

    try {
      const token = localStorage.getItem('token');
      const response = await axios({
        url: `${BASE_URL}/api${url}`,
        method,
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          ...headers,
        },
      });

      return {
        data: response?.data,
        loading: false,
        errorMessage: null,
        successMessage: 'Request successful!',
      };
    } catch (error) {
      console.error('Error request:', error);
      const errorMsg =
        error.response?.data?.message || 'Xatolik Yuzaga Keldi!!!';

      return {
        data: null,
        loading: false,
        errorMessage: errorMsg,
        successMessage: null,
      };
    }
  };

  return { request };
};

export default useAxios;
