import React from 'react';
import classes from './SettingsProfile.module.css';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SettingsProfile = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={classes['text-flex']}>
        <Link to={'/settings'}>
          <h1
            className={
              !window.location.pathname.includes('notification')
                ? 'gradient-txt'
                : ''
            }
          >
            {t('set_info')}
          </h1>
        </Link>
        <Link to={'/settings/notification'}>
          <h1
            className={
              window.location.pathname.includes('notification')
                ? 'gradient-txt'
                : ''
            }
          >
            {t('set_message')}
          </h1>
        </Link>
      </div>

      <Outlet />
    </>
  );
};

export default SettingsProfile;
