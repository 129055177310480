import React, { useContext, useState } from 'react';
import { message } from 'antd';
import { UserContext } from '../../context/userContext/userContext';
import { Icon } from '@iconify/react';
import AuthBackground from '../authBackground/AuthBackground';
import Opener from '../opener/Opener';
import useAxios from '../../useHooks/useAxios/useAxios';
import parse from 'html-react-parser';

import classes from './Login.module.css';
import LoginLanguageChange from './languageChange/LoginLanguageChange';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { setUser } = useContext(UserContext);
  const { request } = useAxios();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    const { data } = await request('/login', {
      method: 'POST',
      body: { email, password },
    });

    if (data) {
      localStorage.setItem('token', data?.token?.token);
      message.success(t('login_success_message'));
      setIsLoggedIn(true);
      setUser(data?.user);
    } else {
      message.error(t('login_error_message'), 2, () => {
        setIsSubmitting(false);
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && email.length && password.length && !isSubmitting) {
      handleSubmit(e);
    }
  };

  if (isLoggedIn) {
    return <Opener />;
  }

  return (
    <div className={classes['main-wrapper']}>
      <AuthBackground />
      <div className={classes['background']}>
        <div className={classes['container']}>
          <h1 className={classes['title']}>{t('login_header')}</h1>
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <div className={classes['input-box']}>
              <p className={classes['input-label']}>{t('login_email')}</p>
              <input
                required
                type='email'
                placeholder='name@gmail.com'
                className={classes['input']}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={classes['input-box']}>
              <p className={classes['input-label']}>{t('login_password')}</p>
              <div className={classes['password-container']}>
                <input
                  required
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('login_password')}
                  className={`${classes['input']} ${classes['password-input']}`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Icon
                  icon={showPassword ? 'mdi:eye' : 'mdi:eye-off'}
                  className={classes['eye-icon']}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
            <button
              type='submit'
              className={classes['btn']}
              disabled={isSubmitting}
              style={{ cursor: isSubmitting ? 'not-allowed' : 'pointer' }}
            >
              {t('login_btn')}
            </button>
          </form>
          <LoginLanguageChange />
          <p className={classes['descr']}>{parse(t('login_descr'))}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
