import React from 'react';
import { Icon } from '@iconify/react';

import classes from './Empty.module.css';
import { useTranslation } from 'react-i18next';

const Empty = ({ margin, title = 'empty_not_exist' }) => {
  const { t } = useTranslation();
  return (
    <div style={{ margin: margin }} className={classes['container']}>
      <Icon className={classes['icon']} icon='hugeicons:package-remove' />
      <h1 className={classes['title']}>{t(title)}</h1>
    </div>
  );
};

export default Empty;
