import React, { createContext, useEffect, useState } from 'react';
import useAxios from '../../useHooks/useAxios/useAxios';

const CompanySettingsContext = createContext(null);

const CompanySettingsContextProvider = ({ children }) => {
  const [settingsData, setSettingsData] = useState({});
  const { request } = useAxios();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const { data } = await request('/settings');
        setSettingsData(data?.data[0]);
      } catch (error) {
        console.error('Error fetching meta data:', error);
      }
    };

    fetchMetaData();
  }, []);

  return (
    <CompanySettingsContext.Provider value={{ settingsData }}>
      {children}
    </CompanySettingsContext.Provider>
  );
};

export { CompanySettingsContextProvider, CompanySettingsContext };
