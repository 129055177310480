import img1 from '../assets/icons/rating-avatar.svg';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const typingRatingColumns = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',

    render: (text, record, index) => <span>{index + 1}</span>,
  },
  {
    title: 'Rasm',
    dataIndex: 'user_image',
    key: 'user_image',
    render: (text) =>
      text ? (
        <img
          className='typing-avatar-img'
          src={`${BASE_URL}/storage/${text}`}
          alt='img-avatar'
        />
      ) : (
        <img src={img1} alt='avatar' />
      ),
  },
  {
    title: 'Ism',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: 'WPM',
    dataIndex: 'max_answer',
    key: 'max_answer',

    render: (text) => <span>{parseInt(text)}</span>,
  },
  {
    title: 'Vaqt',
    dataIndex: 'time',
    key: 'time',
    render: (text) => <span>{text}s</span>,
  },
  {
    title: 'Sana',
    dataIndex: 'created_at',
    key: 'created_at',
  },
];

export const typingRatingData = [
  {
    key: '1',
    number: '1',
    pic: img1,
    ism: 'Jasur',
    wpm: 130,
    sana: '30.07.2024',
  },
  {
    key: '2',
    number: '2',
    pic: img1,
    ism: 'Aziz',
    wpm: 110,
    sana: '29.07.2024',
  },
  {
    key: '3',
    number: '3',
    pic: img1,
    ism: 'Laylo',
    wpm: 106,
    sana: '28.07.2024',
  },
  {
    key: '4',
    number: '4',
    pic: img1,
    ism: 'Kamila',
    wpm: 106,
    sana: '27.07.2024',
  },
  {
    key: '5',
    number: '5',
    pic: img1,
    ism: 'Alisher',
    wpm: 102,
    sana: '26.07.2024',
  },
  {
    key: '6',
    number: '6',
    pic: img1,
    ism: 'Nigina',
    wpm: 98,
    sana: '25.07.2024',
  },
  {
    key: '7',
    number: '7',
    pic: img1,
    ism: 'Farrukh',
    wpm: 97,
    sana: '24.07.2024',
  },
  {
    key: '8',
    number: '8',
    pic: img1,
    ism: 'Zarina',
    wpm: 89,
    sana: '23.07.2024',
  },
  {
    key: '9',
    number: '9',
    pic: img1,
    ism: 'Otabek',
    wpm: 88,
    sana: '22.07.2024',
  },
  {
    key: '10',
    number: '10',
    pic: img1,
    ism: 'Malika',
    wpm: 77,
    sana: '21.07.2024',
  },
];
