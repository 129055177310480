import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { LanguageContext } from '../../../context/languageContext/LanguageContext';

import { languageMenu } from '../../configs/i18nConfig';
import classes from './LanguageDropdown.module.css';

const LanguageDropDownComponent = () => {
  const { i18n } = useTranslation();
  const { setLanguage } = useContext(LanguageContext);
  const [language18, setLanguage18] = useState(languageMenu[1]);

  const handleOnLanguageMenuItemSelected = (item) => {
    if (language18.id !== item.id) {
      i18n.changeLanguage(item.code);
      setLanguage18(item);
      setLanguage(item?.code);
    }
  };

  useEffect(() => {
    const detectedLanguageMenu = languageMenu.find((l) =>
      i18n.language.includes(l.code)
    );

    if (detectedLanguageMenu) {
      setLanguage18(detectedLanguageMenu);
      setLanguage(detectedLanguageMenu?.code);
    }
  }, [i18n.language]);

  const menuItems = languageMenu
    .filter((option) => option.id !== language18.id)
    .map((option) => ({
      key: option.id,
      label: (
        <div
          className={classNames(classes['language_item'], {
            [classes['active_language']]: option.code === language18.code,
          })}
          onClick={() => handleOnLanguageMenuItemSelected(option)}
        >
          <img
            src={option.icon}
            alt={option.title}
            className={classes['language_icon']}
          />
          <span className={classes['language_name']}>{option.title}</span>
        </div>
      ),
    }));

  const menu = <Menu items={menuItems} className={classes['language_menu']} />;

  return (
    <Dropdown overlay={menu} trigger={['hover']} placement='bottomCenter'>
      <div className={classes['language_change_button']}>
        <img
          src={language18.icon}
          alt={language18.title}
          className={classes['language_icon']}
        />
        <DownOutlined className={classes['arrow_icon']} />
      </div>
    </Dropdown>
  );
};

export default LanguageDropDownComponent;
