import React, { useState, useContext } from 'react';
import { UserContext } from '../../../context/userContext/userContext';
import { Icon } from '@iconify/react';
import { message } from 'antd';
import UserIcon from '../../../shared/assets/icons/game-user.svg';
import CustomSpinner from '../../../shared/generics/loader/Loader';
import classes from './SettingsInfo.module.css';
import useAxios from '../../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';

const SettingsInfo = () => {
  const { user, fetchUser, loading } = useContext(UserContext);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { request } = useAxios();
  const { t } = useTranslation();

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const togglePasswordVisibility1 = () => setShowPassword1(!showPassword1);
  const togglePasswordVisibility2 = () => setShowPassword2(!showPassword2);

  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    const { errorMessage } = await request('/profile/updatePhoto', {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (errorMessage) {
      console.error('Error updating photo:', errorMessage);
    } else {
      message.success(t('set_success_img_upload'));
      fetchUser();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password) return message.error(t('set_password_require_error'));
    if (password.length < 8) return message.error(t('set_password_mix_error'));
    if (password !== passwordConfirmation)
      return message.error(t('set_password_confirm_error'));

    const { errorMessage } = await request('/profile/updatePassword', {
      method: 'POST',
      body: {
        password,
        password_confirmation: passwordConfirmation,
      },
    });

    if (errorMessage) {
      console.error('Error updating password:', errorMessage);
      message.error("Parol o'zgarmadi, Qayta urinib ko'ring");
    } else {
      message.success(t('set_success_change_password'));
      setTimeout(() => window.location.reload(), 500);
    }
  };

  if (loading) {
    return (
      <div className={classes['container']}>
        <CustomSpinner />
      </div>
    );
  }

  return (
    <form className={classes['container']} onSubmit={handleSubmit}>
      <div className={classes['photo-container']}>
        <img
          src={user?.image ? `${BASE_URL}/storage/${user?.image}` : UserIcon}
          alt={'img'}
          className={`${classes['profile-img']} ${
            !user?.image && classes['default-img']
          }`}
        />
        <label htmlFor='photo-upload' className={classes['photo-label']}>
          <Icon icon='mdi:camera' className={classes['camera-icon']} />
          <input
            type='file'
            id='photo-upload'
            accept='image/*'
            onChange={handlePhotoChange}
            className={classes['photo-input']}
          />
        </label>
      </div>

      <div className={classes['input-container']}>
        <p className={classes['label']}>{t('set_email')}</p>
        <input
          placeholder={user?.email}
          className={classes['input']}
          disabled
          type='text'
        />
      </div>
      <div className={classes['input-container']}>
        <p className={classes['label']}>{t('set_fullname')}</p>
        <input
          placeholder={user?.name}
          className={classes['input']}
          disabled
          type='text'
        />
      </div>
      <div className={classes['input-container']}>
        <p className={classes['label']}>{t('set_phone')}</p>
        <input
          placeholder={user?.phone}
          className={classes['input']}
          disabled
          type='text'
        />
      </div>

      <hr className={classes['line']} />

      <div className={classes['input-container']}>
        <p className={classes['label']}>{t('set_password')}</p>
        <div className={classes['password-container']}>
          <input
            placeholder={t('set_new_password')}
            className={`${classes['input']} ${classes['password-input']}`}
            type={showPassword1 ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Icon
            icon={showPassword1 ? 'mdi:eye' : 'mdi:eye-off'}
            className={classes['eye-icon']}
            onClick={togglePasswordVisibility1}
          />
        </div>
      </div>
      <div className={classes['input-container']}>
        <p className={classes['label']}>{t('set_password_confirm')}</p>
        <div className={classes['password-container']}>
          <input
            placeholder={t('set_password_confirm')}
            className={`${classes['input']} ${classes['password-input']}`}
            type={showPassword2 ? 'text' : 'password'}
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Icon
            icon={showPassword2 ? 'mdi:eye' : 'mdi:eye-off'}
            className={classes['eye-icon']}
            onClick={togglePasswordVisibility2}
          />
        </div>
      </div>

      <button type='submit' className={classes['btn']}>
        {t('set_password_change')}
      </button>
    </form>
  );
};

export default SettingsInfo;
