import React from 'react';
import { Table } from 'antd';

import './Table.css';
import Empty from '../empty/Empty';

const GenericTable = ({
  columns,
  data,
  handleRowClick,
  emptyTitle = 'empty_not_exist',
}) => {
  return (
    <>
      {data.length === 0 ? (
        <Empty title={emptyTitle} />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'even-row' : 'odd-row'
          }
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      )}
    </>
  );
};

export default GenericTable;
