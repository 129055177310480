import React, { useState, useEffect } from 'react';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Footer from '../../shared/layouts/footer/Footer';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import CourseCategory from './category/Coursecategory';
import classes from './Courses.module.css';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import useAxios from '../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../useHooks/useLanguage/useLanguage';
import Empty from '../../shared/generics/empty/Empty';
import CustomSpinner from '../../shared/generics/loader/Loader';

const Courses = () => {
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(null);
  const { t } = useTranslation();
  const { lang, langUpdater } = useLanguage();

  const { request } = useAxios();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryName = queryParams.get('type');

  const id = categoryData?.filter(
    (value) =>
      value.label.split(' ').join('').slice(1).toLowerCase() === categoryName
  )[0]?.id;

  useEffect(() => {
    setLoading(true);
    const fetchCourses = async () => {
      const {
        data,
        loading: isLoading,
        errorMessage,
      } = await request(`/lesson_categories/${id || 1}`);

      setLoading(isLoading);
      if (errorMessage) {
        console.error('Error fetching courses:', errorMessage);
      } else {
        setData(data?.lessons || []);
      }
    };

    fetchCourses();
  }, [categoryName, langUpdater]);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className={classes['loader-container']}>
          <CustomSpinner />
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className={classes['container']}>
        <h1 className={`${classes['header']} gradient-txt`}>
          {t('course_header')}
        </h1>
        <CourseCategory
          categoryData={categoryData}
          setCategoryData={setCategoryData}
          categoryId={id}
        />
        {data?.length == 0 ? (
          <Empty />
        ) : (
          <div className={classes['cards-wrap']}>
            {data.map((course) => (
              <div className={classes['img']} key={course.id}>
                {parse(lang(course, 'video_url'))}
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
      <Copywrite />
    </>
  );
};

export default Courses;
