import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { message, Dropdown } from 'antd';
import { Icon } from '@iconify/react';
import { useMultiply } from '../../../context/multiplyResultContext/MuptiplyResultContext';
import { UserContext } from '../../../context/userContext/userContext';

import leaveIcon from '../../assets/icons/game-leave.svg';
import crownIcon from '../../assets/icons/game-crown.svg';
import userIcon from '../../assets/icons/game-user.svg';

import classes from './GameNavbar.module.css';
import GenericTooltip from '../../generics/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import LanguageDropDownComponent from '../../generics/languageDropdown/LanguageDropdown';

const GameNavbar = ({ logo }) => {
  const { resetGame } = useMultiply();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleLogout = () => {
    localStorage.removeItem('token');
    message.success(t('logout_success_message'));
    navigate('/');
  };

  const profileMenuItems = [
    {
      key: 'settings',
      label: (
        <div
          className={classes['menu-item']}
          onClick={() => navigate('/settings')}
        >
          <Icon icon='mdi:cog-outline' className={classes['icon']} />
          {t('settings')}
        </div>
      ),
    },
    {
      key: 'logout',
      label: (
        <div className={classes['menu-item']} onClick={handleLogout}>
          <Icon icon='mdi:exit-to-app' className={classes['icon']} />
          {t('logout')}
        </div>
      ),
    },
  ];

  return (
    <div className={classes['container']}>
      <div className={classes['left-box']}>
        <Link
          className={classes['link']}
          to={`/${window.location.pathname.split('/')[1]}`}
        >
          <img
            onClick={resetGame}
            className={classes['logo']}
            src={logo}
            alt='logo'
          />
        </Link>
        <div className={classes['flex']}>
          <GenericTooltip title={t('gn_back_home')}>
            <Link to={'/'}>
              <img
                className={classes['flex-icon']}
                src={leaveIcon}
                alt='leave'
              />
            </Link>
          </GenericTooltip>
          <GenericTooltip title={t('gn_see_rating')}>
            <Link
              to={
                window.location.pathname.includes('multiply')
                  ? '/multiply/rating'
                  : '/typing/rating'
              }
            >
              <img
                className={classes['flex-icon']}
                src={crownIcon}
                alt='crown'
              />
            </Link>
          </GenericTooltip>
        </div>
      </div>
      <div className={classes['right-flex']}>
        <div className={classes['language-box']}>
          <LanguageDropDownComponent />
        </div>
        <Dropdown menu={{ items: profileMenuItems }}>
          {user?.image ? (
            <div className={classes['profile-img-wrapper']}>
              <img
                src={`${BASE_URL}/storage/${user?.image}`}
                className={classes['profile-img']}
                alt='profile'
              />
              <Icon
                icon='iconamoon:arrow-bottom-left-2-bold'
                className={classes['profile-arrow']}
              />
            </div>
          ) : (
            <img
              className={classes['default-user-icon']}
              src={userIcon}
              alt='user'
            />
          )}
        </Dropdown>
      </div>
    </div>
  );
};

export default GameNavbar;
