import React from 'react';
import { Icon } from '@iconify/react';

import classes from './QuizAnswer.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GenericTooltip from '../../shared/generics/tooltip/Tooltip';

const QuizAnswer = ({ result }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let timeString = '';

    if (hours > 0) {
      timeString += `${hours} h `;
    }
    if (minutes > 0) {
      timeString += `${minutes} m `;
    }
    if (remainingSeconds > 0 || timeString === '') {
      timeString += `${remainingSeconds} s`;
    }

    return timeString.trim();
  };

  return (
    <div className={classes['container']}>
      <h1 className={`${classes['header']} gradient-txt`}>
        {t('test_result')}
      </h1>

      <div className={classes['box']}>
        <div className={classes['result-wrap']}>
          <div className={classes['result-box']}>
            <p className={classes['result-type']}>{t('test_spent_time')}</p>
            <p className={`${classes['result-text']} gradient-txt`}>
              {formatTime(result?.time)}
            </p>
          </div>
          <div className={classes['result-box']}>
            <p className={classes['result-type']}>{t('test_correct')}</p>
            <p className={`${classes['result-text']} gradient-txt`}>
              {result?.true_answer} {t('ta')}
            </p>
          </div>
          <div className={classes['result-box']}>
            <p className={classes['result-type']}>{t('test_incorrect')}</p>
            <p className={`${classes['result-text']} gradient-txt`}>
              {result?.false_answer} {t('ta')}
            </p>
          </div>
          <div className={classes['result-box']}>
            <p className={classes['result-type']}>{t('test_score')}</p>
            <p className={`${classes['result-text']} gradient-txt`}>
              {result?.ball.toFixed(1)}
            </p>
          </div>
        </div>
      </div>
      <GenericTooltip title={t('game_try_again')}>
        <Icon
          onClick={() => navigate(-1)}
          icon={'ri:arrow-go-back-line'}
          className={classes['back-icon']}
        />
      </GenericTooltip>
    </div>
  );
};

export default QuizAnswer;
