import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/userContext/userContext';
import Navbar from '../../shared/layouts/navbar/Navbar';
import ProductSidebar from './sidebar/ProductSidebar';
import ProductCategory from './category/ProductCategory';
import ProductCard from './card/ProductCard';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import Footer from '../../shared/layouts/footer/Footer';
import CustomSpinner from '../../shared/generics/loader/Loader';
import classes from './Product.module.css';
import ProductHistory from './history/ProductHistory';
import useAxios from '../../useHooks/useAxios/useAxios';
import Empty from '../../shared/generics/empty/Empty';
import { useTranslation } from 'react-i18next';

const Product = () => {
  const { user } = useContext(UserContext);
  const [productsData, setProductsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const location = useLocation();
  const { request } = useAxios();

  const queryParams = new URLSearchParams(location.search);
  const categoryName = queryParams.get('type') || 'barchasi';

  const fetchProducts = async () => {
    const categoryId = categoryData?.find(
      (value) => value.label.slice(1).toLowerCase() === categoryName
    )?.id;

    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request(`/product_categories/${categoryId || 1512}`);

    setLoading(isLoading);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setProductsData(data?.products || []);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [categoryName]);

  if (error) {
    return (
      <>
        <Navbar />
        <div className={classes['loader-container']}>
          <div className='ec-error'>Muammo: {error}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />

      <div className={classes['container']}>
        <ProductSidebar userCoin={user?.coin} />
        <div className={classes['right-box']}>
          <div className={classes['flex']}>
            <Link to={'/product'}>
              <h1
                className={
                  window.location.pathname === '/product/history'
                    ? classes['title']
                    : classes['title-active']
                }
              >
                {t('product_list')}
              </h1>
            </Link>
            <Link to={'/product/history'}>
              <h1
                className={
                  window.location.pathname !== '/product/history'
                    ? classes['title']
                    : classes['title-active']
                }
              >
                {t('product_history')}
              </h1>
            </Link>
          </div>

          {window.location.pathname === '/product/history' ? (
            <ProductHistory />
          ) : (
            <>
              <ProductCategory
                setCategoryData={setCategoryData}
                categoryData={categoryData}
              />
              {loading ? (
                <div className={classes['loader-container']}>
                  <CustomSpinner />
                </div>
              ) : productsData.length === 0 ? (
                <Empty />
              ) : (
                <div className={classes['card-wrapper']}>
                  {productsData?.map((value) => {
                    return (
                      <ProductCard
                        key={value?.id}
                        data={value}
                        userCoin={user?.coin}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Footer />
      <Copywrite />
    </>
  );
};

export default Product;
