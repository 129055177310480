import React from 'react';

import classes from './AboutHeader.module.css';
import { Link } from 'react-router-dom';
import TeamCard from '../teamCard/TeamCard';
import { teamData } from '../../../shared/utils/teamData';
import { useTranslation } from 'react-i18next';

const AboutHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={classes['container']}>
      <div className={classes['bg']}></div>
      <div className={classes['wrapper']}>
        <h1 className={classes['title']}>{t('about_header')}</h1>
        <p className={classes['descr']}>{t('about_descr')}</p>
        <Link to={'/'}>
          <button className={classes['btn']}>{t('start')}</button>
        </Link>

        <h1 className={`${classes['team-header']} gradient-txt`}>
          {t('about_founders')}
        </h1>
        <div className={classes['team-wrap']}>
          {teamData?.map((value) => {
            return <TeamCard key={value?.id} teamData={value} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutHeader;
