import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './Copywrite.module.css';

const Copywrite = () => {
  const { t } = useTranslation();
  return (
    <div className={classes['main-wrapper']}>
      <div className={classes['container']}>
        <p className={classes['text']}>
          © 2024-2025 #eduCoin <span>{t('footer_protect')}.</span>
        </p>
        <p className={classes['text']}>{t('footer_security')}</p>
      </div>
    </div>
  );
};

export default Copywrite;
