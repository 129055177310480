import React from 'react';

import { UserProvider } from './userContext/userContext';
import { TypingResultProvider } from './typingResultContext/typingResultContext';
import { MultiplyProvider } from './multiplyResultContext/MuptiplyResultContext';
import { LanguageProvider } from './languageContext/LanguageContext';
import { CompanySettingsContextProvider } from './companySettingsContext/CompanySettingsContext';

const MainContextProvider = ({ children }) => {
  return (
    <CompanySettingsContextProvider>
      <LanguageProvider>
        <UserProvider>
          <MultiplyProvider>
            <TypingResultProvider>{children}</TypingResultProvider>
          </MultiplyProvider>
        </UserProvider>
      </LanguageProvider>
    </CompanySettingsContextProvider>
  );
};

export default MainContextProvider;
