import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from './context/userContext/userContext';
import useAxios from './useHooks/useAxios/useAxios';

import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Product from './pages/product/Product';
import Typing from './pages/typing/Typing';
import Multiply from './pages/multiply/Multiply';
import MultiplyResult from './pages/multiplyResult/MultiplyResult';
import Walkthrough from './pages/walkthrough/Walkthrough';
import Courses from './pages/courses/Courses';
import CoinRating from './pages/coinRating/CoinRating';
import TypingRating from './pages/typingRating/TypingRating';
import MultiplyRating from './pages/multiplyRating/MultiplyRating';
import CoinHistory from './pages/coinHistory/CoinHistory';
import Settings from './pages/settings/Settings';
import Quiz from './pages/quiz/Quiz';
import FAQ from './pages/settings/faq/FAQ';
import SettingsProfile from './pages/settings/profile/SettingsProfile';
import EnglishTest from './pages/englishTest/EnglishTest';
import About from './pages/about/About';
import OneNotification from './pages/settings/oneNotification/OneNotification';
import Notification from './pages/settings/notification/Notification';
import SettingsInfo from './pages/settings/info/SettingsInfo';
import TestCategory from './pages/testCategory/TestCategory';
import SettingsLanguage from './pages/settings/language/SettingsLanguage';

const App = () => {
  const { token, setToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { request } = useAxios();

  useEffect(() => {
    if (token) {
      const checkUser = async () => {
        const { data, errorMessage } = await request('/checkUser', {
          method: 'POST',
          body: { token },
        });

        if (errorMessage || !data?.success) {
          localStorage.removeItem('token');
          setToken(null);
          navigate('/login');
        }
      };

      checkUser();
    }
  }, [token, setToken, navigate]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Routes>
      {!token ? (
        <>
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Navigate to='/login' />} />
        </>
      ) : (
        <>
          <Route path='/' element={<Home />} />
          <Route path='/product' element={<Product />} />
          <Route path='/product/history' element={<Product />} />
          <Route path='/coin-rating' element={<CoinRating />} />
          <Route path='/coin-history/:coinId' element={<CoinHistory />} />
          <Route path='/typing' element={<Typing />} />
          <Route path='/typing/rating' element={<TypingRating />} />
          <Route path='/multiply' element={<Multiply />} />
          <Route path='/multiply/result' element={<MultiplyResult />} />
          <Route path='/test/categories' element={<TestCategory />} />
          <Route
            path='/test/category/:testCategoryId'
            element={<EnglishTest />}
          />
          <Route path='/test/:testId' element={<Quiz />} />
          <Route path='/multiply/rating' element={<MultiplyRating />} />
          <Route path='/walkthrough' element={<Walkthrough />} />
          <Route path='/course' element={<Courses />} />
          <Route path='/about' element={<About />} />

          {/* settings */}
          <Route path='/settings' element={<Settings />}>
            <Route path='' element={<SettingsProfile />}>
              <Route path='' element={<SettingsInfo />} />
              <Route path='notification' element={<Notification />} />
            </Route>
            <Route
              path='notification/:messageId'
              element={<OneNotification />}
            />
            <Route path='faq' element={<FAQ />} />
            <Route path='language' element={<SettingsLanguage />} />
          </Route>

          <Route path='*' element={<Navigate to='/' />} />
        </>
      )}
    </Routes>
  );
};

export default App;
