import img1 from '../assets/images/team1.png';
import img2 from '../assets/images/team2.png';
import img3 from '../assets/images/team3.png';

export const teamData = [
  {
    id: 1,
    img: img1,
    name: 'Islombek Baxromov',
    position_uz: 'Full-Stack Dasturchisi',
    position_en: 'Full-Stack Developer',
    position_ru: 'Full-Stack Разработчик',
  },
  {
    id: 2,
    img: img2,
    name: 'Samar Seid',
    position_uz: 'Idea-Maker Hamda Dizayneri',
    position_en: 'Idea Maker and Designer',
    position_ru: 'Создатель Идей и Дизайнер',
  },
  {
    id: 3,
    img: img3,
    name: "Fozilkhan Buzrukxo'jayev",
    position_uz: 'Front-End Dasturchisi',
    position_en: 'Front-End Developer',
    position_ru: 'Front-End Разработчик',
  },
];
