import React, { createContext, useState } from 'react';

const LanguageContext = createContext(null);

const LanguageProvider = ({ children }) => {
  const lang = localStorage.getItem('i18nextLng');
  const [language, setLanguage] = useState(lang ? lang : 'uz');

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
