import React from 'react';
import { Icon } from '@iconify/react'; // Import Iconify component
import userIcon from '../../../shared/assets/icons/rating-avatar.svg';
import questionMark from '../../../shared/assets/icons/question.svg';

import classes from './SettingsSidebard.module.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SettingsSidebar = () => {
  const { t } = useTranslation();
  return (
    <div className={classes['container']}>
      {/* detail */}
      <div className={classes['line']} />
      <h1 className={classes['title']}>{t('settings')}</h1>
      {/* sidebar-items */}
      <Link
        to={'/settings'}
        className={`${classes['item']} ${
          window.location.pathname === '/settings' ||
          window.location.pathname === '/settings/notification'
            ? classes['active']
            : ''
        }`}
      >
        <img className={classes['img']} src={userIcon} alt='user' />
        <p className={classes['text']}>{t('set_profile')}</p>
      </Link>
      <Link
        to={'faq'}
        className={`${classes['item']} ${
          window.location.pathname.includes('faq') ? classes['active'] : ''
        }`}
      >
        <img
          className={classes['img']}
          src={questionMark}
          alt='question-mark'
        />
        <p className={classes['text']}>{t('set_faq')}</p>
      </Link>
      <Link
        to={'language'}
        className={`${classes['item']} ${
          window.location.pathname.includes('language') ? classes['active'] : ''
        }`}
      >
        <Icon icon='mdi:translate' className={classes['img']} />
        <p className={classes['text']}>{t('set_lang')}</p>
      </Link>
    </div>
  );
};

export default SettingsSidebar;
