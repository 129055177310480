import React from 'react';
import CountUp from 'react-countup';

import coinImg from '../../../shared/assets/images/logo_main.png';

import classes from './ProductSidebard.module.css';
import { useTranslation } from 'react-i18next';

const ProductSidebar = ({ userCoin }) => {
  const { t } = useTranslation();

  return (
    <div className={classes['container']}>
      <h1 className={classes['title']}>{t('product_my_coins')}</h1>

      <div className={classes['flex']}>
        <img className={classes['coin-img']} src={coinImg} alt='coin' />
        <p className={`${classes['coin-text']} gradient-txt`}>
          <CountUp end={userCoin || 0} duration={2} separator='' />
        </p>
      </div>
    </div>
  );
};

export default ProductSidebar;
