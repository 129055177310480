export const uzbekWords = [
  'avlod',
  'badiiy',
  'boshlash',
  'butun',
  'daryo',
  'dastur',
  'dil',
  'doira',
  'ilm',
  'ish',
  'javob',
  'kanal',
  'kitob',
  'klass',
  'olma',
  'kuch',
  'meva',
  'muhim',
  'nashr',
  'oliy',
  'ot',
  'professor',
  'pup',
  'qalam',
  'qiziqarli',
  'quyosh',
  'rabot',
  'rat',
  'sabzavot',
  'suv',
  'tashrif',
  'taom',
  'tosh',
  'turli',
  'uskuna',
  'uzun',
  'vazifa',
  'xizmat',
  'yashil',
  'olim',
  'yuz',
  'zavod',
  'zahmat',
  'yangi',
  'ancha',
  'ariq',
  'ayol',
  'baland',
  'buzuq',
  'yurak',
  'kitobxona',
  'malaka',
  'mukofot',
  'mumkin',
  'musobaqa',
  'muvofiq',
  'nashr',
  'otam',
  'sahifa',
  'yolg‘on',
  'yurak',
  'adabiyot',
  'boshqaruvchi',
  'boshqarish',
  'boshqaruvchi',
  'daraja',
  'dastur',
  'dedektiv',
  'devor',
  'ehtiyoj',
  'qosh',
  'namuna',
  'parvoz',
  'poyafzal',
  'qora',
  'quyosh',
  'sanoat',
  'tashkil',
  'ushbu',
  'uzun',
  'zarur',
  'ziddiyat',
  'atmosfera',
  'birinchi',
  'daftar',
  'dars',
  'diplom',
  'dizayn',
  'fakt',
  'hatto',
  'jism',
  'loyiha',
  'maqsad',
  'mustaqil',
  'qisqa',
  'raqam',
  'sifat',
  'sifatli',
  'talaba',
  'tashkilot',
  'turli',
  'xat',
  'yangi',
  'yosh',
  'aql',
  'arxitektura',
  'ashula',
  'barcha',
  'bayroq',
  'boshqaruv',
  'boshqa',
  'davlat',
  'dov',
  'daraja',
  'dunyo',
  'himoya',
  'masala',
  'natija',
  'savol',
  'beshik',
  'terma',
  'tarix',
  'xizmat',
  'yuridik',
  'zamon',
  'aziz',
  'bilim',
  'broshura',
  'mashina',
  'kishi',
  'madaniyat',
  'malaka',
  'nashr',
  'oshpaz',
  'qanday',
  'qulay',
  'sarguzasht',
  'sarlavha',
  'tekshiruv',
  'terma',
  'yaxshi',
  'yosh',
  'zahir',
  'zikr',
  'ziravor',
  'abadiy',
  'boshqaruvchi',
  'hayot',
  'kanal',
  'maqsad',
  'mojaro',
  'mijoz',
  'moneta',
  'qonun',
  'rivojlanish',
  'sayohat',
  'sifatli',
  'tadbir',
  'tashkilot',
  'tuzilma',
  'yangi',
  'yosh',
  'zahir',
  'ziddiyat',
  'soch',
  'zek',
  'yashil',
  'ixtisoslashgan',
];

export const englishWords = [
  'generation',
  'artistic',
  'start',
  'whole',
  'river',
  'program',
  'heart',
  'circle',
  'science',
  'work',
  'answer',
  'channel',
  'book',
  'class',
  'apple',
  'strength',
  'fruit',
  'important',
  'publication',
  'higher',
  'horse',
  'professor',
  'pen',
  'interesting',
  'sun',
  'work',
  'rat',
  'vegetable',
  'water',
  'visit',
  'food',
  'stone',
  'different',
  'equipment',
  'long',
  'task',
  'service',
  'green',
  'scientist',
  'face',
  'factory',
  'effort',
  'new',
  'many',
  'irrigation',
  'woman',
  'high',
  'broken',
  'heart',
  'library',
  'qualification',
  'reward',
  'possible',
  'competition',
  'appropriate',
  'publication',
  'father',
  'page',
  'lie',
  'heart',
  'literature',
  'manager',
  'management',
  'director',
  'degree',
  'program',
  'detective',
  'wall',
  'need',
  'eyebrow',
  'example',
  'flight',
  'shoe',
  'black',
  'sun',
  'industry',
  'organization',
  'this',
  'long',
  'necessary',
  'conflict',
  'atmosphere',
  'first',
  'notebook',
  'lesson',
  'diploma',
  'design',
  'fact',
  'even',
  'body',
  'project',
  'goal',
  'independent',
  'short',
  'number',
  'quality',
  'qualitative',
  'student',
  'organization',
  'various',
  'letter',
  'new',
  'young',
  'mind',
  'architecture',
  'song',
  'all',
  'flag',
  'management',
  'other',
  'state',
  'leader',
  'degree',
  'world',
  'protection',
  'problem',
  'result',
  'question',
  'cradle',
  'team',
  'history',
  'service',
  'legal',
  'time',
  'dear',
  'knowledge',
  'brochure',
  'car',
  'person',
  'culture',
  'qualification',
  'publication',
  'chef',
  'how',
  'comfortable',
  'adventure',
  'headline',
  'examination',
  'team',
  'good',
  'young',
  'reserve',
  'mention',
  'spice',
  'eternal',
  'director',
  'life',
  'channel',
  'goal',
  'conflict',
  'client',
  'coin',
  'law',
  'development',
  'travel',
  'qualitative',
  'event',
  'organization',
  'structure',
  'new',
  'young',
  'reserve',
  'conflict',
  'hair',
  'prisoner',
  'green',
  'specialized',
];

export const russianWords = [
  'поколение',
  'художественный',
  'начало',
  'целый',
  'река',
  'программа',
  'сердце',
  'круг',
  'наука',
  'работа',
  'ответ',
  'канал',
  'книга',
  'класс',
  'яблоко',
  'сила',
  'фрукт',
  'важный',
  'публикация',
  'высший',
  'лошадь',
  'профессор',
  'ручка',
  'интересный',
  'солнце',
  'работа',
  'крыса',
  'овощ',
  'вода',
  'визит',
  'еда',
  'камень',
  'различный',
  'оборудование',
  'длинный',
  'задача',
  'услуга',
  'зелёный',
  'учёный',
  'лицо',
  'завод',
  'усилие',
  'новый',
  'много',
  'орошение',
  'женщина',
  'высокий',
  'сломанный',
  'сердце',
  'библиотека',
  'квалификация',
  'награда',
  'возможно',
  'соревнование',
  'соответствующий',
  'публикация',
  'отец',
  'страница',
  'ложь',
  'сердце',
  'литература',
  'менеджер',
  'управление',
  'директор',
  'степень',
  'программа',
  'детектив',
  'стена',
  'необходимость',
  'бровь',
  'пример',
  'полет',
  'обувь',
  'чёрный',
  'солнце',
  'промышленность',
  'организация',
  'этот',
  'длинный',
  'необходимый',
  'конфликт',
  'атмосфера',
  'первый',
  'тетрадь',
  'урок',
  'диплом',
  'дизайн',
  'факт',
  'даже',
  'тело',
  'проект',
  'цель',
  'независимый',
  'короткий',
  'номер',
  'качество',
  'качественный',
  'студент',
  'организация',
  'различный',
  'письмо',
  'новый',
  'молодой',
  'ум',
  'архитектура',
  'песня',
  'все',
  'флаг',
  'управление',
  'другой',
  'государство',
  'лидер',
  'степень',
  'мир',
  'защита',
  'проблема',
  'результат',
  'вопрос',
  'колыбель',
  'команда',
  'история',
  'услуга',
  'юридический',
  'время',
  'дорогой',
  'знание',
  'брошюра',
  'машина',
  'человек',
  'культура',
  'квалификация',
  'публикация',
  'шеф-повар',
  'как',
  'удобный',
  'приключение',
  'заголовок',
  'экзамен',
  'команда',
  'хороший',
  'молодой',
  'резерв',
  'упоминание',
  'специя',
  'вечный',
  'директор',
  'жизнь',
  'канал',
  'цель',
  'конфликт',
  'клиент',
  'монета',
  'закон',
  'развитие',
  'путешествие',
  'качественный',
  'мероприятие',
  'организация',
  'структура',
  'новый',
  'молодой',
  'резерв',
  'конфликт',
  'волосы',
  'заключённый',
  'зелёный',
  'специализированный',
];
