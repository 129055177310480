import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/userContext/userContext';

import logo from '../../shared/assets/images/logo_main.png';

import classes from './Opener.module.css';
import { useTranslation } from 'react-i18next';

const Opener = () => {
  const { user, setToken } = useContext(UserContext);

  const [showText, setShowText] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 2500);

    return () => clearTimeout(textTimer);
  }, []);

  useEffect(() => {
    if (showText) {
      const homeTimer = setTimeout(() => {
        setShowHome(true);
      }, 5000);

      return () => clearTimeout(homeTimer);
    }
  }, [showText]);

  if (showHome) {
    navigate('/');
    setToken(localStorage.getItem('token'));
  }

  return (
    <div className={classes['container']}>
      {!showText ? (
        <>
          <img className={classes['logo']} src={logo} alt='logo' />
          <div className={classes['shadow']}></div>
        </>
      ) : (
        <div className={classes['text-box']}>
          <h1 className={`${classes['welcome-text']} gradient-txt`}>
            {t('login_welcome')}
            <br />
            <span className={`${classes['name']} gradient-txt`}>
              {user?.name?.split(' ')[0]} !!!
            </span>
          </h1>
        </div>
      )}
    </div>
  );
};

export default Opener;
