import React, { useState, useEffect } from 'react';
import { Skeleton, Modal } from 'antd';
import { Icon } from '@iconify/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import coinImg from '../../../shared/assets/icons/f.svg';
import cartIcon from '../../../shared/assets/icons/cart.svg';

import 'react-lazy-load-image-component/src/effects/blur.css';
import classes from './ProductCard.module.css';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';
import { useTranslation } from 'react-i18next';

const ProductCard = ({ data, userCoin }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [oneProduct, setOneProduct] = useState({});
  const { lang } = useLanguage();
  const { t } = useTranslation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const img = new Image();
    img.src = `${BASE_URL}/storage/Product/${data?.image.slice(2, -2)}`;
    img.onload = () => setImageLoaded(true);
  }, [data?.image]);

  const showModal = (content, selectedData) => {
    setModalContent(content);
    setIsModalVisible(true);
    setOneProduct(selectedData);
  };

  const handleOk = () => {
    if (modalContent === 'confirm') {
      if (parseInt(userCoin) >= parseInt(data?.coin)) {
        purchaseProduct(oneProduct.id);
      } else {
        showModal('error');
      }
    } else {
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const purchaseProduct = (productId) => {
    const token = localStorage.getItem('token');

    fetch(`${BASE_URL}/api/shopProduct`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ product_id: productId }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          showModal('success');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          showModal('error');
        }
      })
      .catch((error) => {
        console.error('Error purchasing product:', error);
        showModal('error');
      });
  };

  return (
    <div className={classes['card']}>
      {!imageLoaded ? (
        <Skeleton.Image active className={classes['img']} />
      ) : (
        <LazyLoadImage
          src={`${BASE_URL}/storage/Product/${data?.image.slice(2, -2)}`}
          alt='product-img'
          className={classes['img']}
          effect='blur'
        />
      )}
      <h5 className={`${classes['name']} gradient-txt`}>
        {lang(data, 'name')}
      </h5>

      <div className={classes['flex']}>
        <img className={classes['coin-img']} src={coinImg} alt='coin-img' />
        <p className={classes['price']}>{data?.coin}</p>
      </div>

      <h4 className={`${classes['count']} gradient-txt`}>
        {data?.count} {t('ta')} {t('product_exist')}
      </h4>
      <p className={classes['color']}>{lang(data, 'info')}</p>

      <div
        className={classes['btn']}
        onClick={() => showModal('confirm', data)}
      >
        <img className={classes['cart']} src={cartIcon} alt='cart-icon' />
        <p className={classes['btn-txt']}>{t('purchase')}</p>
      </div>

      <Modal
        title={t('product_modal_approve')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {modalContent === 'confirm' && (
          <>
            <p className={`${classes['modal-product-name']}`}>
              {oneProduct?.name}
            </p>
            <p className={`${classes['modal-text']} gradient-txt`}>
              {t('product_modal_approve')}
            </p>
            <div className={classes['modal-btn-flex']}>
              <div className={classes['modal-btn']} onClick={handleCancel}>
                <Icon icon='mdi:close' className={classes['modal-icon']} />
                {t('cancel')}
              </div>
              <div className={classes['modal-btn']} onClick={handleOk}>
                <Icon icon='mdi:check' className={classes['modal-icon']} />
                {t('approve')}
              </div>
            </div>
          </>
        )}
        {modalContent === 'success' && (
          <p className={`${classes['modal-text']} `}>
            <span className='gradient-txt'>
              {t('product_congrats')} <br /> {t('product_success_purchase')}
            </span>
            😊
          </p>
        )}
        {modalContent === 'error' && (
          <p className={`${classes['modal-text']} `}>
            <span className='gradient-txt'>{t('product_not_enough_coin')}</span>
            😔
          </p>
        )}
      </Modal>
    </div>
  );
};

export default ProductCard;
