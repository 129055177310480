import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import Typer from '../typer/Typer';
import languageIcon from '../../../shared/assets/icons/game-language.svg';
import classes from './TypingBody.module.css';
import { TypingResultContext } from '../../../context/typingResultContext/typingResultContext';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';
import { LanguageContext } from '../../../context/languageContext/LanguageContext';

const TypingBody = () => {
  const {
    fixedTime,
    setFixedTime,
    setTimer,
    handleLanguageChange,
    language,
    setLanguage,
  } = useContext(TypingResultContext);
  const { t } = useTranslation();
  const { language: languageContext } = useContext(LanguageContext);

  const [isTyping, setIsTyping] = useState(false);

  const handleTimer = (time) => {
    setFixedTime(time);
    setTimer(time);
  };

  const handleMenuClick = (e) => {
    setLanguage(e.key);
    handleLanguageChange(e);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={t('lang_en')}>
        <p className={classes['dropdown-option']}>{t('lang_en')}</p>
      </Menu.Item>
      <Menu.Item key={t('lang_uz')}>
        <p className={classes['dropdown-option']}>{t('lang_uz')}</p>
      </Menu.Item>
      <Menu.Item key={t('lang_ru')}>
        <p className={classes['dropdown-option']}>{t('lang_ru')}</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={classes['container']}>
      <div className={classes['wrapper']}>
        <div
          className={`${classes['system-box']} ${
            isTyping ? classes['hide'] : ''
          }`}
        >
          <div
            onClick={() => handleTimer(15)}
            className={classes['system-item-flex']}
          >
            <p
              className={`${classes['system-text']} ${
                fixedTime === 15 ? 'active' : ''
              }`}
            >
              15 {t('second')}
            </p>
          </div>
          <div className={classes['line']} />
          <div
            onClick={() => handleTimer(30)}
            className={classes['system-item-flex']}
          >
            <p
              className={`${classes['system-text']} ${
                fixedTime === 30 ? 'active' : ''
              }`}
            >
              30 {t('second')}
            </p>
          </div>
          <div className={classes['line']} />
          <div
            onClick={() => handleTimer(60)}
            className={classes['system-item-flex']}
          >
            <p
              className={`${classes['system-text']} ${
                fixedTime === 60 ? 'active' : ''
              }`}
            >
              60 {t('second')}
            </p>
          </div>
        </div>

        <Dropdown overlay={menu}>
          <div
            className={`${classes['language-flex']} ${
              isTyping ? classes['hide'] : ''
            }`}
          >
            <img
              className={classes['language-icon']}
              src={languageIcon}
              alt='language'
            />
            <span className={classes['language-text']}>{language}</span>
          </div>
        </Dropdown>
        <Typer isTyping={isTyping} setIsTyping={setIsTyping} />
      </div>
    </div>
  );
};

export default TypingBody;
