import React, { useState, useEffect, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { UserContext } from '../../../context/userContext/userContext';

import ParticleBackground from '../../../shared/generics/particle/Particle';
import patternParticle from '../../../shared/assets/icons/partical.svg';
import { headerAvatarsData } from '../../../shared/utils/headerAvatarsData';

import 'react-lazy-load-image-component/src/effects/blur.css';
import classes from './Header.module.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [chosenImg, setChosenImg] = useState(
    localStorage.getItem('header_avatar') || headerAvatarsData[0]?.img
  );

  useEffect(() => {
    const cursor = document.querySelector(`.${classes['custom-cursor']}`);

    const handleMouseMove = (e) => {
      const element = document.elementFromPoint(e.clientX, e.clientY);
      if (element) {
        const computedStyle = window.getComputedStyle(element);
        if (computedStyle.cursor === 'pointer') {
          cursor.style.display = 'none';
        } else {
          cursor.style.display = 'block';
          cursor.style.top = `${e.clientY}px`;
          cursor.style.left = `${e.clientX}px`;
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className={classes['container']}>
      <div className={classes['custom-cursor']}></div>
      <ParticleBackground />
      <div className={classes['details']}></div>
      <div className={classes['details-outlined']}></div>
      <div className={classes['shadow']}></div>
      <img className={classes['particle']} src={patternParticle} alt='done' />
      <LazyLoadImage
        src={chosenImg}
        alt='header-img'
        className={classes['img']}
        effect='blur'
      />
      <div className={classes['right-box']}>
        <h1 className={`${classes['header']} gradient-txt`}>
          {t('header_welcome_1')} <br /> {t('header_welcome_2')}
        </h1>
        <h1 className={`${classes['name']} gradient-txt`}>
          {user?.name?.split(' ')[0]}
        </h1>
        <div className={classes['flex']}>
          {headerAvatarsData?.map((value) => {
            return (
              <div
                onClick={() => {
                  localStorage.setItem('header_avatar', value?.img);
                  setChosenImg(value?.img);
                }}
                key={value?.id}
                className={classes['thumb-img-box']}
              >
                <LazyLoadImage
                  className={classes['thumb-img']}
                  src={value?.img}
                  alt='img'
                  effect='blur'
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
