import React, { createContext, useState, useEffect } from 'react';
import useAxios from '../../useHooks/useAxios/useAxios';

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const { request } = useAxios();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request('/getuser');

    setLoading(isLoading);
    if (errorMessage) {
      console.error('Failed to fetch user:', errorMessage);
    } else {
      setUser(data);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [token]);

  return (
    <UserContext.Provider
      value={{ user, setUser, token, setToken, loading, setLoading, fetchUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
