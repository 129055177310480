import React, { useContext, useEffect, useState } from 'react';
import { Radio, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../context/languageContext/LanguageContext';
import './SettingsLanguage.css';
import { languageMenu } from '../../../shared/configs/i18nConfig';

const SettingsLanguage = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { setLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageMenu[1].code
  );

  useEffect(() => {
    const detectedLanguageMenu = languageMenu.find((l) =>
      i18n.language.includes(l.code)
    );

    if (detectedLanguageMenu) {
      setSelectedLanguage(detectedLanguageMenu.code);
      setLanguage(detectedLanguageMenu.code);
    }
  }, [i18n.language, setLanguage]);

  const handleLanguageChange = (e) => {
    const newLanguageCode = e.target.value;
    setSelectedLanguage(newLanguageCode);
    i18n.changeLanguage(newLanguageCode);
    setLanguage(newLanguageCode);
  };

  return (
    <div className='set-lang-container'>
      <h2 className={'set-lang-title'}>{t('set_lang_title')}</h2>
      <Radio.Group onChange={handleLanguageChange} value={selectedLanguage}>
        {languageMenu.map((language) => (
          <Radio key={language.id} value={language.code}>
            <img
              src={language.icon}
              alt={language.title}
              className={'set-lang-languageIcon'}
            />
            <span
              className='set-lang-text
            '
            >
              {language.title}
            </span>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default SettingsLanguage;
