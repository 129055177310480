import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAxios from '../../../useHooks/useAxios/useAxios'; // Adjust path as needed

import classes from './ProductHistory.module.css';
import CustomSpinner from '../../../shared/generics/loader/Loader';
import { useTranslation } from 'react-i18next';

const ProductHistory = () => {
  const [productsHistoryData, setProductsHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { request } = useAxios();
  const { t } = useTranslation();

  const fetchPurchaseHistory = async () => {
    setLoading(true);

    const { data: responseData, errorMessage } = await request('/userShopping');

    setLoading(false);
    if (errorMessage) {
      setError(errorMessage);
      console.error('Error fetching purchase history:', errorMessage);
    } else {
      setProductsHistoryData(responseData?.data || []);
    }
  };

  useEffect(() => {
    fetchPurchaseHistory();
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case '0':
        return classes['status-yellow'];
      case '1':
        return classes['status-green'];
      default:
        return classes['status-yellow'];
    }
  };

  if (loading) {
    return (
      <div className={classes['loader-container']}>
        <CustomSpinner />
      </div>
    );
  }

  if (error) {
    return <div className='ec-error'>Muammo: {error}</div>;
  }

  return (
    <div className={classes['container']}>
      {productsHistoryData.length === 0 ? (
        <p className={classes['no-purchase-text']}>
          {t('product_nothing_bought')}
        </p>
      ) : (
        <div className={classes['history-wrap']}>
          {productsHistoryData.map((item, index) => (
            <div key={index} className={classes['card-wrapper']}>
              <div className={classes['card']}>
                <LazyLoadImage
                  src={item?.product_image}
                  alt='product-img'
                  className={classes['img']}
                  effect='blur'
                />
                <div className={classes['info']}>
                  <div
                    className={`${classes['status-badge']} ${getStatusClass(
                      item?.status
                    )}`}
                  >
                    {item?.status === '0'
                      ? t('product_waiting')
                      : t('product_receieved')}
                  </div>
                  <h5 className={classes['name']}>{item?.product_name}</h5>
                  <p className={classes['price']}>
                    Coin:
                    <span className={`${classes['bold']} gradient-txt`}>
                      {' '}
                      {item?.product_coin}
                    </span>
                  </p>
                  <p className={classes['date']}>{item?.purchase_date}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductHistory;
