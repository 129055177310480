import React, { useContext } from 'react';
import { TypingResultContext } from '../../context/typingResultContext/typingResultContext';

import GameNavbar from '../../shared/layouts/gameNavbar/GameNavbar';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import GenericTooltip from '../../shared/generics/tooltip/Tooltip';

import statisticTypingImg from '../../shared/assets/images/wpm.png';
import refreshIcon from '../../shared/assets/icons/game-refresh.svg';

import typingLogo from '../../shared/assets/icons/edu-type.svg';

import classes from './TypingResult.module.css';
import { useTranslation } from 'react-i18next';

const TypingResult = () => {
  const { result, fixedTime, resetTime } = useContext(TypingResultContext);
  const { t } = useTranslation();
  return (
    <div className={classes['main-container']}>
      <GameNavbar logo={typingLogo} />

      <div className={classes['container']}>
        <div className={classes['wrapper']}>
          <div className={classes['text-box']}>
            <h1>wpm</h1>
            <h1>{parseInt(result?.wpm)}</h1>
          </div>
          <img
            className={classes['img']}
            src={statisticTypingImg}
            alt='typing-img'
          />
        </div>

        <div className={classes['flex']}>
          <div className={`${classes['box']} ${classes['wpm-box']}`}>
            <h3 className={classes['descr']}>{t('game_speed')}</h3>
            <p className={classes['result-text']}>
              {parseInt(result?.wpm)} wpm
            </p>
          </div>
          <div className={classes['box']}>
            <h3 className={classes['descr']}>{t('game_letters')}</h3>
            <GenericTooltip title={"to'g'ri/noto'g'ri"}>
              <p className={classes['result-text']}>
                {result?.letterFlow}/{result?.incorrectLetters}
              </p>
            </GenericTooltip>
          </div>
          <div className={classes['box']}>
            <h3 className={classes['descr']}>{t('game_time')}</h3>
            <p className={classes['result-text']}>{fixedTime}s</p>
          </div>
        </div>

        <div className={classes['settings-flex']}>
          <GenericTooltip title={t('game_try_again')}>
            <img
              onClick={resetTime}
              className={classes['icon']}
              src={refreshIcon}
              alt='refresh-icon'
            />
          </GenericTooltip>
        </div>
      </div>

      <div className={classes['bottom-box']}>
        <Copywrite />
      </div>
    </div>
  );
};

export default TypingResult;
