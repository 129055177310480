import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { UserContext } from '../../../context/userContext/userContext';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

import fImg from '../../../shared/assets/images/logo_main.png';
import xpImg from '../../../shared/assets/icons/xp.svg';
import levelImg from '../../../shared/assets/icons/level.svg';
import statisticBg from '../../../shared/assets/icons/statistic-bg.svg';

import classes from './Statistics.module.css';

const Statistics = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className={classes['container']} ref={ref}>
      <div className={classes['wrapper']}>
        <div className={classes['flex']}>
          <img src={fImg} alt='f-img' className={classes['f-img']} />
          <h1 className={`${classes['text']} gradient-txt`}>
            {inView ? (
              <CountUp end={user?.coin || 0} duration={2.5} separator='' />
            ) : (
              '0'
            )}
          </h1>
        </div>
        <div className={classes['flex']}>
          <img src={xpImg} alt='xp-img' className={classes['xp-img']} />
          <h1 className={`${classes['text']} gradient-txt`}>5420</h1>
        </div>
      </div>
      <div className={classes['bottom-flex']}>
        <img src={levelImg} alt='level-img' className={classes['level-img']} />
        <h1
          className={`${classes['text']} ${classes['level-text']} gradient-txt`}
        >
          {`2-${t('stats_level')}`}
        </h1>
      </div>

      <h3 className={`${classes['position-txt']} gradient-txt`}>
        {t('stats_place')}
      </h3>
      <h6 className={classes['level-txt']}>{t('stats_leader')}</h6>
      <img
        src={statisticBg}
        alt='statistic-bg'
        className={classes['statistic-bg']}
      />
    </div>
  );
};

export default Statistics;
