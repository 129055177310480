
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
      background: 'linear-gradient(96.52deg, #bac5ff 9.98%, #a05fff 93.94%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }}
    spin
  />
);

const CustomSpinner = () => {
  return <Spin indicator={antIcon} />;
};

export default CustomSpinner;
