import React from 'react';
import { Tooltip } from 'antd';
import './Tooltip.css';

const GenericTooltip = ({ title, children, placement, color }) => {
  return (
    <Tooltip
      title={<span className='custom-tooltip'>{title}</span>}
      placement={'bottom'}
      color={color}
    >
      {children}
    </Tooltip>
  );
};

export default GenericTooltip;
