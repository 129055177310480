import img1 from '../assets/icons/rating-avatar.svg';
import coinImg from '../assets/icons/f.svg';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const coinRaintingColumns = [
  { title: '#', dataIndex: 'number', key: 'number' },
  {
    title: 'Rasm',
    dataIndex: 'user_image',
    key: 'user_image',
    render: (text) =>
      text ? (
        <img
          className='typing-avatar-img'
          src={`${BASE_URL}/storage/${text}`}
          alt='img-avatar'
        />
      ) : (
        <img src={img1} alt='avatar' />
      ),
  },
  { title: 'Ism', dataIndex: 'ism', key: 'ism' },
  {
    title: '#eduCoin',
    dataIndex: 'eduCoin',
    key: 'eduCoin',
    render: (text) => (
      <div className='table-coin-box-flex'>
        <img src={coinImg} alt='coin' />
        {text}
      </div>
    ),
  },
  { title: 'Fillial', dataIndex: 'fillial', key: 'fillial' },
  { title: 'Guruh', dataIndex: 'guruh', key: 'guruh' },
];

export const coinRatingData = [
  {
    key: '1',
    number: '1',
    pic: img1,
    ism: 'Jasur',
    fillial: 'Qoraqamish',
    guruh: 'Web dasturlash A-3',
    eduCoin: '500',
    sana: '30.07.2024',
  },
  {
    key: '2',
    number: '2',
    pic: img1,
    ism: 'Aziz',
    fillial: 'Chilonzor',
    guruh: 'Data Science B-2',
    eduCoin: '450',
    sana: '29.07.2024',
  },
  {
    key: '3',
    number: '3',
    pic: img1,
    ism: 'Laylo',
    fillial: 'Yakkasaroy',
    guruh: 'AI C-1',
    eduCoin: '400',
    sana: '28.07.2024',
  },
  {
    key: '4',
    number: '4',
    pic: img1,
    ism: 'Kamila',
    fillial: 'Mirzo Ulugbek',
    guruh: 'Mobile Dev D-4',
    eduCoin: '350',
    sana: '27.07.2024',
  },
  {
    key: '5',
    number: '5',
    pic: img1,
    ism: 'Alisher',
    fillial: 'Yunusabad',
    guruh: 'Game Dev E-3',
    eduCoin: '300',
    sana: '26.07.2024',
  },
  {
    key: '6',
    number: '6',
    pic: img1,
    ism: 'Nigina',
    fillial: 'Sergeli',
    guruh: 'Web dasturlash A-3',
    eduCoin: '250',
    sana: '25.07.2024',
  },
  {
    key: '7',
    number: '7',
    pic: img1,
    ism: 'Farrukh',
    fillial: 'Olmazor',
    guruh: 'Data Science B-2',
    eduCoin: '200',
    sana: '24.07.2024',
  },
  {
    key: '8',
    number: '8',
    pic: img1,
    ism: 'Zarina',
    fillial: 'Bektemir',
    guruh: 'AI C-1',
    eduCoin: '150',
    sana: '23.07.2024',
  },
  {
    key: '9',
    number: '9',
    pic: img1,
    ism: 'Otabek',
    fillial: 'Shayhontohur',
    guruh: 'Mobile Dev D-4',
    eduCoin: '100',
    sana: '22.07.2024',
  },
  {
    key: '10',
    number: '10',
    pic: img1,
    ism: 'Malika',
    fillial: 'Uchtepa',
    guruh: 'Game Dev E-3',
    eduCoin: '50',
    sana: '21.07.2024',
  },
];
