import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import GenericTable from '../../shared/generics/table/Table';
import CustomSpinner from '../../shared/generics/loader/Loader';
import classes from './CoinHisotry.module.css';
import { coinHistoryColumns } from '../../shared/utils/coinHistoryData';
import GenericPagination from '../../shared/generics/pagination/GenericPagination';
import useAxios from '../../useHooks/useAxios/useAxios';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Footer from '../../shared/layouts/footer/Footer';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../useHooks/useLanguage/useLanguage';

const CoinHistory = () => {
  const { request } = useAxios();
  const { coinId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { lang, langUpdater } = useLanguage();

  const currentPage = parseInt(query.get('page')) || 1;
  const activeButton = query.get('type') || 'barchaVaqt';

  const [coinHistoryData, setCoinHistoryData] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data, errorMessage } = await request(
        `/reyting${
          activeButton === 'kunlik' ? '/today' : ``
        }/${coinId}?page=${currentPage}`
      );
      if (errorMessage) {
        throw new Error(errorMessage);
      }

      const response = data?.results?.data?.map((value, index) => ({
        key: index + 1,
        number: index + 1,
        coin: value?.coin,
        kimdan: value?.teacher_name,
        izoh: lang(value, 'comment'),
        status: value?.type === '1',
        sana: value?.created_at,
      }));

      setCoinHistoryData(response);
      setUser(data?.user);
      setTotal(data?.results?.total || 0);
    } catch (error) {
      console.error('Error fetching coin history data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, activeButton, langUpdater]);

  const handlePageChange = (page) => {
    navigate(`?page=${page}&type=${activeButton}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleButtonClick = (buttonType) => {
    navigate(`?page=1&type=${buttonType}`);
  };

  if (error) {
    return <div className='ec-error'>Muammo: {error}</div>;
  }

  return (
    <>
      <Navbar />
      <div className={classes['main-container']}>
        <Link to={'/coin-rating'}>
          <button className={`${classes['btn']} ${classes['go-back-btn']}`}>
            <svg
              width='20'
              height='20'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M8 1L2 8L8 15' stroke='#fff' strokeWidth='2' />
            </svg>
            {t('back')}
          </button>
        </Link>

        <div className={classes['container']}>
          <div className={classes['header-box']}>
            <h1 className={classes['title']}>
              {user?.name} {t('coin_history_all')}
            </h1>
            <div className={classes['btn-flex']}>
              <button
                className={`${classes['btn']} ${
                  activeButton === 'barchaVaqt' ? classes['active'] : ''
                } ${classes['double-btn']}`}
                onClick={() => handleButtonClick('barchaVaqt')}
              >
                {t('rt_btn_all')}
              </button>
              <button
                className={`${classes['btn']} ${
                  activeButton === 'kunlik' ? classes['active'] : ''
                } ${classes['double-btn']}`}
                onClick={() => handleButtonClick('kunlik')}
              >
                {t('rt_btn_day')}
              </button>
            </div>
          </div>
          <p className={classes['descr']}>{t('rt_update')}</p>
          {loading ? (
            <div className={classes['loader-container']}>
              <CustomSpinner />
            </div>
          ) : (
            <div className={`${classes['table-container']} table-container`}>
              <GenericTable
                columns={coinHistoryColumns}
                data={coinHistoryData}
                handleRowClick={() => {}}
              />
            </div>
          )}
          <GenericPagination
            current={currentPage}
            total={total}
            pageSize={10}
            onChange={handlePageChange}
          />
        </div>
      </div>
      <Footer />
      <Copywrite />
    </>
  );
};

export default CoinHistory;
