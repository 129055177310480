import React, { useState, useEffect } from 'react';
import { Collapse } from 'antd';
import './FAQ.css';
import CustomSpinner from '../../../shared/generics/loader/Loader';
import useAxios from '../../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';

const { Panel } = Collapse;

const FAQ = () => {
  const { request } = useAxios();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const fetchFaqs = async () => {
    setLoading(true);
    const { data, loading: isLoading, errorMessage } = await request('/faqs');

    setLoading(isLoading);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setFaqs(data?.data);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);


  return (
    <div className='faq-container'>
      <h1 className='faq-title'>{t('set_faq_title')}</h1>

      {loading ? (
        <div className='faq-loader-container'>
          <CustomSpinner />
        </div>
      ) : error ? (
        <div className='ec-error'>Error: {error}</div>
      ) : (
        <Collapse className='accordion' accordion>
          {faqs.map((faq, index) => (
            <Panel
              header={lang(faq, 'question')}
              key={index + 1}
              className='panel'
            >
              <p className='panel-content'>{lang(faq, 'answer')}</p>
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default FAQ;
