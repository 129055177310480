import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../useHooks/useAxios/useAxios';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';

import './CourseCategory.css';

const CourseCategory = ({ setCategoryData, categoryData, categoryId }) => {
  const [activeCategory, setActiveCategory] = useState('');
  const navigate = useNavigate();
  const { request } = useAxios();
  const { lang, langUpdater } = useLanguage();

  const fetchCategories = async () => {
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request('/lesson_categories');

    if (errorMessage) {
      console.error('Error fetching course categories:', errorMessage);
    } else {
      const newData = data.map((value) => ({
        label: `#${lang(value, 'name')}`,
        id: value?.id,
      }));

      setCategoryData(newData);
      setActiveCategory(newData[categoryId - 1]?.label || '');
      handleCategoryChange(newData[categoryId - 1]?.label || '');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [langUpdater]);

  const handleCategoryChange = (value) => {
    setActiveCategory(value);
    const categoryName = value?.slice(1);
    navigate(`/course?type=${categoryName?.split(' ').join('').toLowerCase()}`);
  };

  return (
    <div className='course-category-container'>
      <Segmented
        options={categoryData.map((item) => item.label)}
        value={activeCategory}
        onChange={handleCategoryChange}
      />
    </div>
  );
};

export default CourseCategory;
