import img1 from '../assets/images/carousel1.png';
import img2 from '../assets/images/carousel2.png';
import img3 from '../assets/images/carousel3.png';

export const posibiltiesCarouselData = [
  {
    id: 1,
    img: img1,
    title_uz: 'Tez Yozishni #eduType Bilan O’rganing',
    title_en: 'Learn Fast Typing with #eduType',
    title_ru: 'Учитесь быстро печатать с #eduType',
  },
  {
    id: 2,
    img: img2,
    title_uz: 'Karra Jadvalni #eduMultiply Bilan O’rganing',
    title_en: 'Learn the Multiplication Table with #eduMultiply',
    title_ru: 'Изучайте таблицу умножения с #eduMultiply',
  },
  {
    id: 3,
    img: img3,
    title_uz: 'Sohangizga Oid Kontentlarni Faqat #eduGram Dan Toping',
    title_en: 'Find Content Related to Your Field Only on #eduGram',
    title_ru: 'Находите контент по своей сфере только на #eduGram',
  },
];
