import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Footer from '../../shared/layouts/footer/Footer';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import SettingsSidebar from './sidebar/SettingsSidebar';

import classes from './Settings.module.css';

const Settings = () => {
  return (
    <div className={classes['main-container']}>
      <Navbar />

      <div className={classes['container']}>
        <SettingsSidebar />

        <div className={classes['right-box']}>
          <Outlet />
        </div>
      </div>

      <div className={classes['circle-container']}>
        <div className={classes['shadow']} />
      </div>

      <Footer />
      <Copywrite />
    </div>
  );
};

export default Settings;
