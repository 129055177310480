import React from 'react';
import { Link } from 'react-router-dom';
import { useMultiply } from '../../context/multiplyResultContext/MuptiplyResultContext';

import GameNavbar from '../../shared/layouts/gameNavbar/GameNavbar';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';

import refreshIcon from '../../shared/assets/icons/game-refresh.svg';
import statisticTypingImg from '../../shared/assets/images/wpm.png';
import multiplyLogo from '../../shared/assets/images/multiply-logo.png';
import GenericTooltip from '../../shared/generics/tooltip/Tooltip';

import classes from './MultiplyResult.module.css';
import { useTranslation } from 'react-i18next';

const MultiplyResult = () => {
  const { correctCount, incorrectCount, calculateSpeed, resetGame } =
    useMultiply();
  const { t } = useTranslation();

  const speed = calculateSpeed();

  return (
    <div className={classes['main-container']}>
      <GameNavbar logo={multiplyLogo} />

      <div className={classes['container']}>
        <div className={classes['wrapper']}>
          <div className={classes['text-box']}>
            <h1>{t('game_speed')}</h1>
            <h1>{speed}</h1>
          </div>
          <img
            className={classes['img']}
            src={statisticTypingImg}
            alt='typing-img'
          />
        </div>

        <div className={classes['flex']}>
          <div className={classes['box']}>
            <h3 className={classes['descr']}>{t('game_correct')}</h3>
            <p className={classes['result-text']}>{correctCount} marta</p>
          </div>
          <div className={classes['box']}>
            <h3 className={classes['descr']}>{t('game_incorrect')}</h3>
            <p className={classes['result-text']}>{incorrectCount} marta</p>
          </div>
          <div className={classes['box']}>
            <h3 className={classes['descr']}>{t('game_time')}</h3>
            <p className={classes['result-text']}>15s</p>
          </div>
        </div>

        <div className={classes['settings-flex']}>
          <Link to={'/multiply'}>
            <GenericTooltip title={t('game_try_again')}>
              <img
                onClick={() => resetGame()}
                className={classes['icon']}
                src={refreshIcon}
                alt='refresh-icon'
              />
            </GenericTooltip>
          </Link>
        </div>
      </div>

      <div className={classes['bottom-box']}>
        <Copywrite />
      </div>
    </div>
  );
};

export default MultiplyResult;
