import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import { posibiltiesCarouselData } from '../../../shared/utils/posibiltiesCarouselData';

import arrowIcon1 from '../../../shared/assets/icons/arrow-pos.svg';

import './Posibilities.css';
import 'swiper/css';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';

const Posibilities = () => {
  const { lang } = useLanguage();
  return (
    <div className='posibilities-swiper-container'>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        navigation={{
          nextEl: '.posibilities-swiper-button-next',
          prevEl: '.posibilities-swiper-button-prev',
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className='posibilities-my-swiper'
        modules={[Navigation, Autoplay]}
      >
        {posibiltiesCarouselData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className='posibilities-card'>
              <div className='posibilities-card-title gradient-txt'>
                {lang(item, 'title')}
              </div>
              <img
                src={item.img}
                alt={lang(item, 'title')}
                className='posibilities-card-img'
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='posibilities-swiper-button-next'>
        <img src={arrowIcon1} alt='Next' />
      </div>
      <div className='posibilities-swiper-button-prev'>
        <img src={arrowIcon1} alt='Prev' />
      </div>
    </div>
  );
};

export default Posibilities;
