import img1 from '../assets/images/avatar-1.png';
import img2 from '../assets/images/avatar-2.png';
import img3 from '../assets/images/avatar-3.png';
import img4 from '../assets/images/avatar-4.png';
import img5 from '../assets/images/avatar-5.png';

export const headerAvatarsData = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
];
