import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

import classes from './NotificationReminder.module.css';
import useAxios from '../../../useHooks/useAxios/useAxios';

const NotificationReminder = () => {
  const { request } = useAxios();
  const navigate = useNavigate();
  const [unreadNotificationLength, setUnreadNotificationLength] = useState(0);

  const handleClick = () => {
    navigate('/settings/notification?search=1');
  };

  const fetchNotifications = async () => {
    const { data } = await request(`/profile/message/1`);

    setUnreadNotificationLength(data?.data?.total);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      {unreadNotificationLength > 0 && (
        <div className={classes['notification-bubble']} onClick={handleClick}>
          <div className={classes['icon-container']}>
            <Icon className={classes['icon']} icon='mdi:message' />
            <span className={classes['notification-count']}>
              {unreadNotificationLength}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationReminder;
