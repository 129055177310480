import React, { useState, useEffect, useRef, useContext } from 'react';
import { TypingResultContext } from '../../../context/typingResultContext/typingResultContext';
import { UserContext } from '../../../context/userContext/userContext';

import classes from './Typer.module.css';
import useAxios from '../../../useHooks/useAxios/useAxios';

const Typer = ({ isTyping, setIsTyping }) => {
  const { request } = useAxios();
  const { updateResult, timer, setTimer, fixedTime, words, language } =
    useContext(TypingResultContext);
  const { user } = useContext(UserContext);

  const [wordList, setWordList] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [typedWord, setTypedWord] = useState('');
  const [correctWords, setCorrectWords] = useState(0);
  const [mostlyCorrect, setMostlyCorrect] = useState(0);
  const [fullyCorrect, setFullyCorrect] = useState(0);
  const [incorrectWords, setIncorrectWords] = useState(0);
  const [letterFlow, setLetterFlow] = useState(0);
  const [incorrectLetters, setIncorrectLetters] = useState(0);
  const [letterStroke, setLetterStroke] = useState(0);

  const inputRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    generateWordList();
    inputRef.current.focus();
  }, [language]);

  useEffect(() => {
    if (isTyping && timer > 0) {
      timerRef.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isTyping, timer]);

  useEffect(() => {
    if (timer === 1) {
      calculateResult();
      sendResult();
    }
  }, [timer]);

  const generateWordList = () => {
    const screenWidth = window.innerWidth;
    let wordNum;

    if (screenWidth < 360) {
      wordNum = 8;
    } else if (screenWidth < 480) {
      wordNum = 20;
    } else if (screenWidth < 600) {
      wordNum = 30;
    } else {
      wordNum = 60;
    }

    const lineNum = 30;
    const newWordList = [];

    for (let i = 0; i < lineNum; i++) {
      const line = [];
      for (let j = 0; j < wordNum; j++) {
        const randNum = Math.floor(Math.random() * words.length);
        line.push(words[randNum]);
      }
      newWordList.push(line);
    }
    setWordList(newWordList);
  };

  const sendResult = async () => {
    const { errorMessage } = await request('/answers', {
      method: 'POST',
      body: {
        type_id: 0,
        user_id: user.id,
        answer: wpm,
        time: fixedTime,
        other_data: 'it is empty',
      },
    });

    if (errorMessage) {
      console.error('Error sending result:', errorMessage);
    } else {
      console.log('Result sent successfully');
    }
  };

  const resetTest = () => {
    setWordList([]);
    setTypedWord('');
    setCurrentWordIndex(0);
    setTimer(fixedTime);
    setIsTyping(false);
    setCorrectWords(0);
    setMostlyCorrect(0);
    setFullyCorrect(0);
    setIncorrectLetters(0);
    setIncorrectWords(0);
    setLetterFlow(0);
    setLetterStroke(0);
    generateWordList();
    inputRef.current.focus();
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setTypedWord(value);

    if (!isTyping) {
      setIsTyping(true);
    }

    if (value.endsWith(' ')) {
      checkWord(value.trim());
      setTypedWord('');
    }
  };

  const checkWord = (word) => {
    const currentLine = wordList[0];
    const currentWord = currentLine[currentWordIndex];
    let correctLetterCount = 0;

    for (let i = 0; i < word.length; i++) {
      if (word[i] === currentWord[i]) {
        correctLetterCount++;
      } else {
        setIncorrectLetters((prev) => prev + 1);
      }
    }

    if (word === currentWord) {
      setCorrectWords((prev) => prev + 1);
      setFullyCorrect((prev) => prev + 1);
    } else if (correctLetterCount >= currentWord.length / 2) {
      setCorrectWords((prev) => prev + 1);
      setMostlyCorrect((prev) => prev + 1);
    } else {
      setIncorrectWords((prev) => prev + 1);
    }

    setLetterFlow((prev) => prev + correctLetterCount);
    setLetterStroke((prev) => prev + word.length);

    if (currentWordIndex < currentLine.length - 1) {
      setCurrentWordIndex((prev) => prev + 1);
    } else {
      setWordList((prev) => prev.slice(1));
      setCurrentWordIndex(0);
    }
  };

  const totalTypedWords = correctWords + mostlyCorrect + fullyCorrect;
  const totalMinutes = fixedTime / 60;
  const wpm = (totalTypedWords / totalMinutes).toFixed(2);

  const calculateResult = () => {
    updateResult({
      correctWords: correctWords,
      mostlyCorrect: mostlyCorrect,
      fullyCorrect: fullyCorrect,
      letterFlow: letterFlow,
      incorrectLetters: incorrectLetters,
      incorrectWords: incorrectWords,
      letterStroke: letterStroke,
      wpm: wpm,
    });
  };

  const getWordClass = (wordIndex) => {
    if (wordIndex < currentWordIndex) {
      return classes.correctWord;
    } else if (wordIndex === currentWordIndex) {
      return classes.currWord;
    } else {
      return '';
    }
  };

  const getLetterClass = (word, letter, letterIndex, wordIndex) => {
    if (wordIndex === currentWordIndex && typedWord[letterIndex] !== letter) {
      return classes.incorrectWord;
    }
    return '';
  };

  return (
    <section className={classes.testContainer}>
      <div className={classes.countDown}>{timer}s</div>

      <div className={classes.output}>
        {wordList[0] &&
          wordList[0].map((word, wordIndex) => (
            <span
              key={wordIndex}
              className={`${classes.word} ${getWordClass(wordIndex)}`}
            >
              {word.split('').map((letter, letterIndex) => (
                <span
                  key={letterIndex}
                  className={`${classes.wordLetter} ${getLetterClass(
                    word,
                    letter,
                    letterIndex,
                    wordIndex
                  )}`}
                >
                  {letter}
                </span>
              ))}
              {wordIndex < wordList[0].length - 1 && ' '}
            </span>
          ))}
      </div>
      <div className={classes.wordInput}>
        <input
          ref={inputRef}
          className={classes.typeWord}
          type='text'
          value={typedWord}
          onChange={handleInputChange}
        />
        <button className={classes.refresh} onClick={resetTest}>
          ↻
        </button>
      </div>
    </section>
  );
};

export default Typer;
