import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMultiply } from '../../context/multiplyResultContext/MuptiplyResultContext';
import { UserContext } from '../../context/userContext/userContext';

import GameNavbar from '../../shared/layouts/gameNavbar/GameNavbar';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';

import multiplyLogo from '../../shared/assets/images/multiply-logo.png';

import classes from './Multiply.module.css';
import useAxios from '../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';

const Multiply = () => {
  const navigate = useNavigate();
  const {
    timer,
    setCorrectCount,
    correctCount,
    setIncorrectCount,
    setTimer,
    setStartTime,
  } = useMultiply();

  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const [num1, setNum1] = useState(Math.floor(Math.random() * 10) + 1);
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10) + 1);
  const [answer, setAnswer] = useState('');
  const [isStarted, setIsStarted] = useState(false);
  const [fixedTime, setFixedTime] = useState(15);
  const inputRef = useRef(null);
  const timerRef = useRef(null);

  const { request } = useAxios();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (isStarted && timer > 0) {
      timerRef.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerRef.current);
      navigate('/multiply/result');
      sendResult();
    }

    return () => clearInterval(timerRef.current);
  }, [isStarted, timer, navigate, setTimer]);

  const generateNewProblem = () => {
    setNum1(Math.floor(Math.random() * 11));
    setNum2(Math.floor(Math.random() * 11));
    setAnswer('');
  };

  const checkAnswer = () => {
    const correctAnswer = num1 * num2;
    if (parseInt(answer) === correctAnswer) {
      setCorrectCount((prev) => prev + 1);
    } else {
      setIncorrectCount((prev) => prev + 1);
    }
    generateNewProblem();
  };

  const handleInputChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (answer.length && e.key === 'Enter') {
      if (!isStarted) {
        setIsStarted(true);
        setStartTime(Date.now());
      }
      checkAnswer();
    }
  };

  const handleTimer = (time) => {
    setFixedTime(time);
    setTimer(time);
  };

  const sendResult = async () => {
    request('/answers', {
      method: 'POST',
      body: {
        type_id: 1,
        user_id: user.id,
        answer: correctCount,
        time: fixedTime,
        other_data: 'it is empty',
      },
    });
  };

  return (
    <div className={classes['main-container']}>
      <GameNavbar logo={multiplyLogo} />
      <div className={classes['container']}>
        <div className={classes['system-box']}>
          <div
            onClick={() => handleTimer(15)}
            className={classes['system-item-flex']}
          >
            <p
              className={`${classes['system-text']} ${
                fixedTime === 15 && classes['active']
              }`}
            >
              15 {t('second')}
            </p>
          </div>
          <div className={classes['line']} />
          <div
            onClick={() => handleTimer(30)}
            className={classes['system-item-flex']}
          >
            <p
              className={`${classes['system-text']} ${
                fixedTime === 30 && classes['active']
              }`}
            >
              30 {t('second')}
            </p>
          </div>
          <div className={classes['line']} />
          <div
            onClick={() => handleTimer(60)}
            className={classes['system-item-flex']}
          >
            <p
              className={`${classes['system-text']} ${
                fixedTime === 60 && classes['active']
              }`}
            >
              60 {t('second')}
            </p>
          </div>
        </div>
        <div className={classes['timer']}>{timer}s</div>

        <div className={classes['circle']}>
          <h1 className={classes['output']}>{`${num1} * ${num2} = ?`}</h1>
          <input
            ref={inputRef}
            className={classes['input']}
            type='number'
            value={answer}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            inputMode='numeric'
          />
        </div>
      </div>
      <div className={classes['bottom-container']}>
        <Copywrite />
      </div>
    </div>
  );
};

export default Multiply;
