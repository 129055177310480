const missionsData = [
  {
    id: 1,
    title_uz: 'Hammasi bitta uchun',
    title_en: 'All for One',
    title_ru: 'Все для одного',
    descr_uz:
      'Biz qilayotgan hamma narsa foydalanuvchilar va jamoaning umumiy maqsadlari uchun, har bir bolakayga berilgan bilimga platformamiz qo’shgan hissasini qadrlaymiz.',
    descr_en:
      'Everything we do is for the shared goals of our users and team, appreciating the contribution our platform makes to each child’s education.',
    descr_ru:
      'Все, что мы делаем, направлено на общие цели наших пользователей и команды, ценим вклад, который наша платформа вносит в обучение каждого ребенка.',
    shadowClass: 'shadow-left',
  },
  {
    id: 2,
    title_uz: 'Sabr-toqatda davom eting',
    title_en: 'Persist with Patience',
    title_ru: 'Проявляйте терпение',
    descr_uz:
      "Qat'iylik va qat'iyatni olqishlash, bu o'zingizga ishonish, maqsadlarga e'tibor qaratish va yanada katta muvaffaqiyatlarga erishishdir.",
    descr_en:
      'Encouraging perseverance and determination means believing in yourself, focusing on goals, and achieving greater success.',
    descr_ru:
      'Поощрение настойчивости и решимости означает верить в себя, сосредоточиваться на целях и достигать больших успехов.',
    shadowClass: 'shadow-right',
  },
  {
    id: 3,
    title_uz: 'Innovatsiyalarga ochiq',
    title_en: 'Open to Innovation',
    title_ru: 'Открытость инновациям',
    descr_uz:
      "Yangi g'oyalarni qabul qilish, hamkorlikni rag'batlantirish va muvaffaqiyatga erishish uchun yaxshiroq echimlarni topishga moslashish.",
    descr_en:
      'Embracing new ideas, fostering collaboration, and adapting to find better solutions to achieve success.',
    descr_ru:
      'Принятие новых идей, поощрение сотрудничества и адаптация для поиска лучших решений для достижения успеха.',
    shadowClass: 'shadow-left',
  },
  {
    id: 4,
    title_uz: 'Mukammalik bilan Yetakchilik',
    title_en: 'Leading with Excellence',
    title_ru: 'Ведение с совершенством',
    descr_uz:
      'Mukammallikka intilish, eng yuqori sifatli xizmatni taqdim etish.',
    descr_en:
      'Striving for excellence and delivering the highest quality service.',
    descr_ru:
      'Стремление к совершенству и предоставление услуг высочайшего качества.',
    shadowClass: 'shadow-right',
  },
];

export default missionsData;
