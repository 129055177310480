import React, { useContext, useEffect } from 'react';

import GameNavbar from '../../shared/layouts/gameNavbar/GameNavbar';

import typingLogo from '../../shared/assets/icons/edu-type.svg';

import TypingBody from './body/TypingBody';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import { TypingResultContext } from '../../context/typingResultContext/typingResultContext';
import TypingResult from '../typingResult/TypingResult';

const Typing = () => {
  const { timer, setTimer, fixedTime } = useContext(TypingResultContext);

  useEffect(() => {
    setTimer(fixedTime);
  }, []);

  return (
    <>
      {timer === 0 ? (
        <TypingResult />
      ) : (
        <>
          <GameNavbar logo={typingLogo} />
          <TypingBody />
          <Copywrite />
        </>
      )}
    </>
  );
};

export default Typing;
