import React, { useEffect, useState } from 'react';
import classes from './EnglishTest.module.css';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Footer from '../../shared/layouts/footer/Footer';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import { Link, useParams } from 'react-router-dom';
import CustomSpinner from '../../shared/generics/loader/Loader';
import useAxios from '../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../useHooks/useLanguage/useLanguage';

const EnglishTest = () => {
  const [levels, setLevels] = useState({});
  const { request } = useAxios();
  const [loading, setLoading] = useState(true);
  const { testCategoryId } = useParams();
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const fetchSubjects = async () => {
    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request(`/test_subjects/${testCategoryId}`);

    setLoading(isLoading);
    if (errorMessage) {
      console.error('Error fetching subjects:', errorMessage);
    } else {
      setLevels(data || {});
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, [testCategoryId]);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className={classes['loader-container']}>
          <CustomSpinner />
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className={classes['container']}>
        <h1 className={`${classes['header']} gradient-txt`}>
          {t('please')} {lang(levels, 'name')} {t('test_category_choose')}
        </h1>
        <div className={classes['levels-container']}>
          {levels?.topics?.map((level) => {
            const name = lang(level, 'name');
            return (
              <Link to={`/test/${level.id}`} key={level.id}>
                <div className={classes['level-card']}>
                  <h2>{name.replace(/"/g, '')}</h2>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Footer withoutMargin={true} />
      <Copywrite />
    </>
  );
};

export default EnglishTest;
