import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd';
import { useNavigate } from 'react-router-dom';

import './ProductCategory.css';
import useAxios from '../../../useHooks/useAxios/useAxios';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';

const ProductCategory = ({ setCategoryData, categoryData }) => {
  const [activeCategory, setActiveCategory] = useState('');
  const navigate = useNavigate();
  const { request } = useAxios();
  const { lang, langUpdater } = useLanguage();

  const fetchCategories = async () => {
    const { data, errorMessage } = await request('/product_categories');

    if (errorMessage) {
      console.error('Error fetching product categories:', errorMessage);
    } else {
      const newData = data.map((value) => ({
        label: `#${lang(value, 'name')}`,
        id: value?.id,
      }));

      newData.reverse();
      setCategoryData(newData);
      setActiveCategory(newData[0]?.label || '');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [langUpdater]);

  const handleCategoryChange = (value) => {
    setActiveCategory(value);
    const categoryName = value.slice(1);
    navigate(`/product?type=${categoryName.toLowerCase()}`);
  };

  return (
    <div className='product-category-container'>
      <Segmented
        options={categoryData.map((item) => item.label)}
        value={activeCategory}
        onChange={handleCategoryChange}
      />
    </div>
  );
};

export default ProductCategory;
