import React from 'react';

import img1 from '../../../shared/assets/images/about-galary1.png';
import img2 from '../../../shared/assets/images/about-galary2.png';

import classes from './AboutMissions.module.css';
import missionsData from '../../../shared/utils/missionsData';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';

const AboutMissions = () => {
  const { t } = useTranslation();
  const { lang } = useLanguage();
  return (
    <div className={classes['container']}>
      <h1 className={`${classes['title']} gradient-txt`}>
        {t('about_missions')}
      </h1>
      <div className={classes['card-wrap']}>
        {missionsData?.map((mission) => (
          <div
            key={mission.id}
            className={`${classes['box']} ${classes[mission.shadowClass]}`}
          >
            <h3 className={classes['subtitle']}>{lang(mission, 'title')}</h3>
            <p className={classes['descr']}>{lang(mission, 'descr')}</p>
          </div>
        ))}
      </div>

      <div className={classes['imgs-wrap']}>
        <img className={classes['img']} src={img1} alt='img1' />
        <img className={classes['img']} src={img2} alt='img2' />
      </div>
    </div>
  );
};

export default AboutMissions;
