import React, { useEffect, useState } from 'react';
import useAxios from '../../useHooks/useAxios/useAxios';
import { Icon } from '@iconify/react';

import classes from './TestCategory.module.css';
import CustomSpinner from '../../shared/generics/loader/Loader';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import Footer from '../../shared/layouts/footer/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../useHooks/useLanguage/useLanguage';

const TestCategory = () => {
  const { request } = useAxios();
  const [testCategoriesData, setTestCategoriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const fetchTestCategories = async () => {
    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request(`/test_subjects`);

    setLoading(isLoading);
    if (errorMessage) {
      console.error('Error fetching subjects:', errorMessage);
    } else {
      setTestCategoriesData(data || []);
    }
  };

  useEffect(() => {
    fetchTestCategories();
  }, []);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className={classes['loader-container']}>
          <CustomSpinner />
        </div>
      </>
    );
  }

  const getCategoryIcon = (categoryName) => {
    switch (categoryName) {
      case 'Web dasturlash':
        return 'mdi:web';
      case 'Matematika':
        return 'mdi:calculator-variant';
      case 'Ingliz tili':
        return 'icon-park-outline:english';
      default:
        return 'mdi:book-open-page-variant';
    }
  };

  return (
    <>
      <Navbar />
      <h1 className={`${classes['header']} gradient-txt`}>
        {t('test_subject_choose')}
      </h1>
      <div className={classes['categories-container']}>
        {testCategoriesData?.map((category) => (
          <Link to={`/test/category/${category?.id}`}>
            <div key={category.id} className={classes['category-card']}>
              <Icon
                icon={getCategoryIcon(lang(category, 'name'))}
                className={classes['category-icon']}
              />
              <div className={classes['category-content']}>
                <h3 className={classes['category-name']}>
                  {lang(category, 'name')}
                </h3>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <Footer />
      <Copywrite />
    </>
  );
};

export default TestCategory;
