import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classes from './Notification.module.css';
import CustomSpinner from '../../../shared/generics/loader/Loader';
import GenericPagination from '../../../shared/generics/pagination/GenericPagination';
import useAxios from '../../../useHooks/useAxios/useAxios';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';
import NotificationCategory from './category/NotificationCategory';
import Empty from '../../../shared/generics/empty/Empty';

const Notification = () => {
  const { request } = useAxios();
  const [notificationsData, setNotificationsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useLanguage();

  const query = new URLSearchParams(location.search);
  const searchChecked = parseInt(query.get('search')) || 0;
  const currentPage = parseInt(query.get('page')) || 0;

  const fetchNotifications = async () => {
    setLoading(true);
    const {
      data,
      loading: isLoading,
      errorMessage,
    } = await request(`/profile/message/${searchChecked}?page=${currentPage}`);

    setLoading(isLoading);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setNotificationsData(data?.data);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [currentPage, searchChecked]);

  if (error) {
    return <div className='ec-error'>Muammo: {error}</div>;
  }

  const handlePageChange = (page) => {
    navigate(`?page=${page}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <NotificationCategory />
      {loading ? (
        <div className={classes['loading']}>
          <CustomSpinner />
        </div>
      ) : notificationsData?.data?.length == 0 ? (
        <Empty />
      ) : (
        notificationsData?.data.map((value) => {
          const message = lang(value, 'message');
          return (
            <div key={value?.id} className={classes['container']}>
              <p className={classes['date']}>{value?.created_at}</p>
              <Link to={`/settings/notification/${value?.id}`}>
                <div className={classes['box']}>
                  <div
                    className={`${classes['status-badge']} ${
                      value?.status === '0'
                        ? classes['status-success']
                        : classes['status-error']
                    }`}
                  >
                    {value?.status === '0'
                      ? t('set_message_seen')
                      : t('set_message_unseen')}
                  </div>
                  {message?.length >= 40 ? (
                    <span>{message?.slice(0, 40)}...</span>
                  ) : (
                    message
                  )}
                </div>
              </Link>
            </div>
          );
        })
      )}
      <GenericPagination
        current={currentPage}
        total={notificationsData?.total}
        pageSize={10}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Notification;
