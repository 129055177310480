import coinImg from '../assets/icons/f.svg';
import tickIcon from '../assets/icons/rating-tick.svg';
import errorIcon from '../assets/icons/rating-error.svg';

export const coinHistoryColumns = [
  { title: '#', dataIndex: 'number', key: 'number' },
  {
    title: 'Coin',
    dataIndex: 'coin',
    key: 'coin',
    render: (text) => (
      <div className='table-coin-box-flex'>
        <img src={coinImg} alt='coin' />
        {text}
      </div>
    ),
  },
  { title: 'Kimdan', dataIndex: 'kimdan', key: 'kimdan' },
  { title: 'Izoh', dataIndex: 'izoh', key: 'izoh' },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) =>
      text ? (
        <img src={tickIcon} alt='tick' />
      ) : (
        <img src={errorIcon} alt='error' />
      ),
  },
  { title: 'Sana', dataIndex: 'sana', key: 'sana' },
];

export const coinHistoryData = [
  {
    key: '1',
    number: '1',
    coin: 500,
    kimdan: 'Islombek Baxromov',
    izoh: 'Haftalik Post G’olibi',
    status: true,
    sana: '30.07.2024',
  },
  {
    key: '2',
    number: '2',
    coin: '-400',
    kimdan: 'Islombek Baxromov',
    izoh: 'Uyga Vazifa Bajarilmagan',
    status: false,
    sana: '30.07.2024',
  },
  {
    key: '3',
    number: '3',
    coin: 200,
    kimdan: 'Islombek Baxromov',
    izoh: 'Kunlik Coin',
    status: true,
    sana: '30.07.2024',
  },
  {
    key: '4',
    number: '4',
    coin: 400,
    kimdan: 'Islombek Baxromov',
    izoh: 'Uyga Vazifa Bajarilgan',
    status: true,
    sana: '30.07.2024',
  },
  {
    key: '5',
    number: '5',
    coin: 400,
    kimdan: 'Islombek Baxromov',
    izoh: 'Uyga Vazifa Bajarilgan',
    status: true,
    sana: '30.07.2024',
  },
  {
    key: '6',
    number: '6',
    coin: '-300',
    kimdan: 'Islombek Baxromov',
    izoh: 'Sababsiz kelmagan',
    status: false,
    sana: '30.07.2024',
  },
];
