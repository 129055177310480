import React from 'react';

import Navbar from '../../shared/layouts/navbar/Navbar';
import AboutHeader from './header/AboutHeader';
import AboutMissions from './missions/AboutMissions';
import Posibilities from '../home/posibilities/Posibilities';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import Footer from '../../shared/layouts/footer/Footer';

const About = () => {
  return (
    <>
      <Navbar />
      <AboutHeader />
      <AboutMissions />
      <Posibilities />
      <Footer />
      <Copywrite />
    </>
  );
};

export default About;
