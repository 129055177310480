import { useContext } from 'react';
import { LanguageContext } from '../../context/languageContext/LanguageContext';

const useLanguage = () => {
  const { language } = useContext(LanguageContext);

  const lang = (data, key) => {
    if (!data || !key) return '';
    return data[`${key}_${language}`] || '';
  };

  return { lang, langUpdater: language };
};

export default useLanguage;
