import React from 'react';
import { Modal, Calendar } from 'antd';
import moment from 'moment';

import arrowIcon from '../../../shared/assets/icons/arrow-attendence.svg';

import './Attendence.css';

const AttendenceModal = ({ isModalVisible, setIsModalVisible }) => {
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dateFullCellRender = (date) => {
    const currentDate = date.date();
    const day = date.day();

    const attendance = [
      { date: 7, month: '07', status: 'absent' },
      { date: 2, month: '08', status: 'absent' },
      { date: 7, month: '08', status: 'absent' },
      { date: 19, month: '08', status: 'absent' },
    ];

    const isAbsent = attendance.some(
      (item) =>
        item.date === currentDate &&
        date.isSame(moment(`2024-${item?.month}-01`).startOf('month'), 'month')
    );

    let cellClass = '';
    if ((day === 1 || day === 3 || day === 5) && !isAbsent) {
      cellClass = 'attended';
    } else if (isAbsent) {
      cellClass = 'absent';
    }

    return <div className={`custom-date-cell ${cellClass}`}>{currentDate}</div>;
  };

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const month = value.format('MMMM');
    return (
      <div className='custom-calendar-header'>
        <h2 className='calendar-title gradient-txt'>Davomat</h2>
        <div className='calendar-controls'>
          <div
            className='arrow-box'
            onClick={() => onChange(value.clone().subtract(1, 'month'))}
          >
            <img
              className='right-arrow-icon'
              src={arrowIcon}
              alt='arrow-icon'
            />
          </div>
          <span className='current-month'>{month}</span>
          <div
            className='arrow-box'
            onClick={() => onChange(value.clone().add(1, 'month'))}
          >
            <img src={arrowIcon} alt='arrow-icon' />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width='70%'
      style={{ top: 20 }}
      centered
    >
      <Calendar
        className='custom-calendar'
        headerRender={headerRender}
        fullCellRender={dateFullCellRender}
      />
    </Modal>
  );
};

export default AttendenceModal;
