import React, { useContext } from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './Footer.module.css';
import { CompanySettingsContext } from '../../../context/companySettingsContext/CompanySettingsContext';

const Footer = ({ withoutMargin }) => {
  const { t } = useTranslation();
  const { settingsData } = useContext(CompanySettingsContext);

  return (
    <div
      style={{ marginTop: withoutMargin ? '0px' : undefined }}
      className={classes['container']}
    >
      <div className={classes['wrapper']}>
        <div className={classes['first-column']}>
          <h1 className={`${classes['logo']} gradient-txt`}>EduCoin.</h1>
          <p className={classes['social-media-title']}>{t('footer_join')}</p>
          <div className={classes['social-media-flex']}>
            {settingsData?.telegram_link && (
              <a
                href={settingsData?.telegram_link}
                target='_blank'
                rel='noreferrer'
              >
                <div className={classes['circle']}>
                  <Icon icon='mdi:telegram' className={classes['icon']} />
                </div>
              </a>
            )}
            {settingsData?.instagram_link && (
              <a
                href={settingsData?.instagram_link}
                target='_blank'
                rel='noreferrer'
              >
                <div className={classes['circle']}>
                  <Icon icon='mdi:instagram' className={classes['icon']} />
                </div>
              </a>
            )}
            {settingsData?.youtube_link && (
              <a
                href={settingsData?.youtube_link}
                target='_blank'
                rel='noreferrer'
              >
                <div className={classes['circle']}>
                  <Icon icon='mdi:youtube' className={classes['icon']} />
                </div>
              </a>
            )}
          </div>
        </div>

        <div className={classes['box']}>
          <h3 className={`${classes['box-category-title']} gradient-txt`}>
            {t('game')}
          </h3>
          <Link className={classes['item-link']} to={'/typing'}>
            {t('typing')}
          </Link>
          <Link className={classes['item-link']} to={'/multiply'}>
            {t('multiply')}
          </Link>
          <Link className={classes['item-link']} to={'/test/categories'}>
            {t('test')}
          </Link>
        </div>

        <div className={classes['box']}>
          <h3 className={`${classes['box-category-title']} gradient-txt`}>
            {t('source')}
          </h3>
          <Link className={classes['item-link']} to={'/walkthrough'}>
            {t('walkthrough')}
          </Link>
          <Link className={classes['item-link']} to={'/course'}>
            {t('video_course')}
          </Link>
        </div>

        <div className={classes['box']}>
          <h3 className={`${classes['box-category-title']} gradient-txt`}>
            {t('company')}
          </h3>
          <Link className={classes['item-link']} to={'/about'}>
            {t('about_us')}
          </Link>
          <Link className={classes['item-link']} to='/about'>
            {t('blog')}
          </Link>
          <Link className={classes['item-link']} to='/news'>
            {t('news')}
          </Link>
        </div>

        <div className={classes['last-column']}>
          <h3 className={classes['contact-text']}>{t('footer_contact')}</h3>
          <div className={classes['input-box']}>
            <input
              placeholder='info@educoin.com'
              type='text'
              className={classes['input']}
            />
            <div className={classes['icon-box']}>{'>'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
