import React, { useContext } from 'react';
import { UserContext } from '../../context/userContext/userContext';

import CustomSpinner from '../../shared/generics/loader/Loader';
import Navbar from '../../shared/layouts/navbar/Navbar';
import Statistics from './statistics/Statistics';
import Footer from '../../shared/layouts/footer/Footer';
import Copywrite from '../../shared/layouts/copywrite/Copywrite';
import Header from './header/Header';
import Posibilities from './posibilities/Posibilities';
import NotificationReminder from '../../shared/generics/notificationReminder/NotificationReminder';

import classes from './Home.module.css';

const Home = () => {
  const { loading } = useContext(UserContext);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className={classes['spinner-container']}>
          <CustomSpinner />
        </div>
      </>
    );
  }

  return (
    <div>
      <div className={classes['opener-container']}>
        <Navbar />
        <Header />
      </div>
      <Statistics />
      <Posibilities />
      <Footer />
      <Copywrite />

      <NotificationReminder />
    </div>
  );
};

export default Home;
