import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LanguageContext } from '../../../context/languageContext/LanguageContext';

import classes from './LoginLanguageChange.module.css';

const languages = [
  { id: 'uz', title: 'UZ' },
  { id: 'en', title: 'EN' },
  { id: 'ru', title: 'RU' },
];

const LoginLanguageChange = () => {
  const { i18n } = useTranslation();
  const { setLanguage } = useContext(LanguageContext);
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const detectedLanguage = languages.find((l) => l.id === i18n.language);
    if (detectedLanguage) {
      setActiveLanguage(detectedLanguage.id);
      setLanguage(detectedLanguage.id);
    }
  }, [i18n.language, setLanguage]);

  return (
    <div className={classes['language-container']}>
      {languages.map((lang, index) => (
        <span key={lang.id}>
          <span
            className={classNames(classes['language-option'], {
              [classes['active-language']]: activeLanguage === lang.id,
            })}
            onClick={() => handleLanguageChange(lang.id)}
          >
            {lang.title}
          </span>
          {languages.length !== index + 1 && (
            <span className={classes['slash']}>|</span>
          )}
        </span>
      ))}
    </div>
  );
};

export default LoginLanguageChange;
