import React from 'react';

import classes from './TeamCard.module.css';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../useHooks/useLanguage/useLanguage';

const TeamCard = ({ teamData }) => {
  const { t } = useTranslation();
  const { lang } = useLanguage();
  return (
    <div className={teamData?.id === 3 && classes['full-width']}>
      <div
        className={`${classes['card']} ${
          teamData?.id % 2 === 0
            ? classes['shadow-left']
            : classes['shadow-right']
        }`}
      >
        <img className={classes['img']} src={teamData?.img} alt='teamData' />
        <p className={classes['name']}>{teamData?.name}</p>
        <p className={`${classes['position']} gradient-txt`}>
          {t('about_team_text')} <br />
          {lang(teamData, 'position')}
        </p>
      </div>
    </div>
  );
};

export default TeamCard;
